import * as React from "react";
import { makeStyles } from "@material-ui/styles";

import { createSvgIcon } from "@material-ui/core";

// prettier-ignore
const ALL_ICONS = {
  Level_0of2: createSvgIcon( <path fill="currentColor" d="M19.5,5.5V18.5H17.5V5.5H19.5M12.5,10.5V18.5H10.5V10.5H12.5M5.5,15.5V18.5H3.5V15.5H5.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level02" ),
  Level_1of2: createSvgIcon( <path fill="currentColor" d="M19.5,5.5V18.5H17.5V5.5H19.5M12.5,10.5V18.5H10.5V10.5H12.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level12" ),
  Level_2of2: createSvgIcon(<path fill="currentColor" d="M19.5,5.5V18.5H17.5V5.5H19.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level22"),
  Level_0of3: createSvgIcon( <path fill="currentColor" d="M19.5,5.5V18.5H17.5V5.5H19.5M12.5,10.5V18.5H10.5V10.5H12.5M5.5,15.5V18.5H3.5V15.5H5.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level03" ),
  Level_1of3: createSvgIcon( <path fill="currentColor" d="M19.5,5.5V18.5H17.5V5.5H19.5M12.5,10.5V18.5H10.5V10.5H12.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level13" ),
  Level_2of3: createSvgIcon(<path fill="currentColor" d="M19.5,5.5V18.5H17.5V5.5H19.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level23"),
  Level_3of3: createSvgIcon(<path fill="currentColor" d="M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />, "Level33"),
};

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  icon: {
    marginRight: 4,
    marginTop: 2,
    height: 32,
    width: 32,
    fontSize: "28px",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

// const valuesToIcons = {
//     ""
// }

const Enum = React.memo(function Speed(props) {
  const { value } = props;
  const classes = useStyles();

  let icon = null;
  if (value && value.icon) {
    icon = ALL_ICONS[value.icon];
  }

  //   console.log("value is", value, icon);

  return (
    <div className={classes.root}>
      {icon && <span className={classes.icon}>{React.createElement(icon)}</span>}
      {value && value.label ? <span className={classes.label}>{value.label}</span> : <span></span>}
    </div>
  );
});

export function renderEnum(params) {
  if (params.value == null) {
    return (
      <div>
        <span></span>
      </div>
    );
  }
  return <Enum value={params.value} />;
}
