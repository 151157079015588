import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  SvgIcon,
  Typography,
  Drawer,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ButtonRow from "./ButtonRow";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  drawer: {
    margin: theme.spacing(2),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    display: "inline-block",
  },
}));

const writeParamsList = [
  "Setpoint",
  "P0_Valor",
  "P1_Valor",
  "P3_Valor",
  "P4_Valor",
  "P5_Valor",
  "P6_Valor",
  "P7_Valor",
  "P8_Valor",
  "P9_Valor",
  "dH_Valor",
  "PF_Valor",
  "Pd_Valor",
  "SL_Valor",
  "PL_Valor",
  "EC_Valor",
  "dA_Valor",
  "tL_Valor",
  "Refrigeracao",
  "Velocidade_da_Ventilacao",
  "Limpador_Traseiro",
  "Limpador_Dianteiro",
  "Farol_Dianteiro",
  "Farol_Traseiro",
  "Esguicho_de_Agua",
  "Carga_de_Gas",
  "Reset_dos_Parametros",
];

export default function AutoGrid({ params }) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const columns = [
    { flex: 0.5, field: "paramName", headerName: "Parameter" },
    {
      flex: 1,
      field: "paramValue",
      headerName: "Value (hex)",
      valueFormatter: (params) => {
        if (!params.value) return "0x0";
        const valueHex = params.value.toString(16).toUpperCase();
        return `0x${valueHex}`;
      },
    },
  ];

  const rows = _.keys(params).map((key, idx) => ({
    id: idx,
    paramName: key,
    paramValue: params[key] || "",
  }));

  const writeColumns = [
    { flex: 0.5, field: "paramName", headerName: "Parameter" },
    {
      flex: 1,
      field: "paramValue",
      headerName: "Value (hex)",
      editable: true,
      valueFormatter: (params) => {
        if (!params.value) return "0x0";
        const valueHex = params.value.toString(16).toUpperCase();
        return `0x${valueHex}`;
      },
    },
  ];

  const writeRows = writeParamsList.map((key, idx) => ({
    id: idx,
    paramName: key,
    paramValue: params[key] || "0",
  }));

  return (
    <div className={classes.root}>
      <Drawer
        variant="persistent"
        anchor={"right"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Paper className={[classes.paper, classes.drawer]}>
          <Typography variant="h4" component="h2">
            Ventilação
          </Typography>
          <SvgIcon style={{ fontSize: 60, margin: 20 }}>
            <path d="M12 11a1 1 0 100 2 1 1 0 000-2zm.5-9c4.5 0 4.61 3.57 2.25 4.75-.99.49-1.43 1.54-1.62 2.47.48.2.9.51 1.22.91 3.7-2 7.68-1.21 7.68 2.37 0 4.5-3.57 4.6-4.75 2.23-.5-.99-1.56-1.43-2.49-1.62-.2.48-.51.89-.91 1.23 1.99 3.69 1.2 7.66-2.38 7.66-4.5 0-4.59-3.58-2.23-4.76.98-.49 1.42-1.53 1.62-2.45-.49-.2-.92-.52-1.24-.92C5.96 15.85 2 15.07 2 11.5 2 7 5.56 6.89 6.74 9.26c.5.99 1.55 1.42 2.48 1.61.19-.48.51-.9.92-1.22C8.15 5.96 8.94 2 12.5 2z" />
          </SvgIcon>
          <Box>
            <ButtonRow />
          </Box>
        </Paper>
      </Drawer>
      <Grid container spacing={4}>
        <Grid item md={12} lg={4}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2">
              Ventilação
            </Typography>
            <SvgIcon style={{ fontSize: 60, margin: 20 }}>
              <path d="M12 11a1 1 0 100 2 1 1 0 000-2zm.5-9c4.5 0 4.61 3.57 2.25 4.75-.99.49-1.43 1.54-1.62 2.47.48.2.9.51 1.22.91 3.7-2 7.68-1.21 7.68 2.37 0 4.5-3.57 4.6-4.75 2.23-.5-.99-1.56-1.43-2.49-1.62-.2.48-.51.89-.91 1.23 1.99 3.69 1.2 7.66-2.38 7.66-4.5 0-4.59-3.58-2.23-4.76.98-.49 1.42-1.53 1.62-2.45-.49-.2-.92-.52-1.24-.92C5.96 15.85 2 15.07 2 11.5 2 7 5.56 6.89 6.74 9.26c.5.99 1.55 1.42 2.48 1.61.19-.48.51-.9.92-1.22C8.15 5.96 8.94 2 12.5 2z" />
            </SvgIcon>
            <Box>
              <ButtonRow />
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12} lg={4}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2">
              Temperatura
            </Typography>
            <SvgIcon style={{ fontSize: 60, margin: 20 }}>
              <path d="M9 16a3.001 3.001 0 006 0c0-.353-.072-.686-.184-1H9.184A2.962 2.962 0 009 16z" />
              <path d="M18 6V4h-3.185A2.995 2.995 0 0012 2c-1.654 0-3 1.346-3 3v5.8A6.027 6.027 0 006 16c0 3.309 2.691 6 6 6s6-2.691 6-6a6.027 6.027 0 00-3-5.2V10h3V8h-3V6h3zm-4.405 6.324A4.033 4.033 0 0116 16c0 2.206-1.794 4-4 4s-4-1.794-4-4c0-1.585.944-3.027 2.405-3.676l.595-.263V5a1 1 0 012 0v7.061l.595.263z" />
            </SvgIcon>
            <Box>
              <ButtonRow />
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12} lg={8}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2">
              Parâmetros escrita
            </Typography>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                autoHeight
                rows={writeRows}
                columns={writeColumns}
                pageSize={10}
                // checkboxSelection
                // disableSelectionOnClick
              />
            </div>
            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  setDrawerOpen(true);
                }}
              >
                Write changes
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12} lg={8}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2">
              Parâmetros leitura
            </Typography>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                pageSize={10}
                // checkboxSelection
                // disableSelectionOnClick
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
