import { Avatar, Box, Card, Container, Grid, Paper, Typography } from "@material-ui/core";
import { deepOrange, pink } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import { lighten, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useParams } from "react-router-dom";
import ClientBreadcrumbs from "../components/ClientBreadcrumbs";
import { Constants, Drawer as MenuLateral } from "../components/Drawer";
import useClient from "../hooks/useClient";
import { SelectionProvider } from "../hooks/useSelectionContext";

import ClientIcon from "@material-ui/icons/BusinessOutlined";
import DeviceIcon from "@material-ui/icons/LocalShippingOutlined";
import UserIcon from "@material-ui/icons/PersonOutline";
import AlertIcon from "@material-ui/icons/ReportProblemOutlined";

function GridItem({ classes }) {
  return (
    // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
    // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
    // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
    <Grid item xs={12} sm={6} md={3}>
      <Paper className={classes.paper}>item</Paper>
    </Grid>
  );
}

export default function Device() {
  const classes = useStyles();

  const { loading, error, path, client: myClient, currentSlug: current } = useClient();

  const makeAddClientLink = () => {
    return current == "root" ? `/client/new` : `/client/${current}/new`;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuLateral />
      <Container m={3}>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          // `resolvedData` will either resolve to the latest page's data
          // or if fetching a new page, the last successful page's data
          <div>
            <Box mt={1} mb={1}>
              <ClientBreadcrumbs />
            </Box>

            <Box display="flex" alignItems="space-between" mb={4}>
              <Box flexGrow={1}>
                <Typography variant="h4" component="h1">
                  Client <b>{myClient.name}</b> details
                </Typography>
                {/* <div>{JSON.stringify(myPath)}</div> */}
              </Box>
              <div>
                {/* <Button startIcon={<AddIcon />} variant="outlined" color="secondary" component={RouterLink} to={makeAddClientLink()}>
                    Add client
                  </Button> */}
              </div>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Card>
                  <Box sx={{ px: 6, py: 3 }} display="flex" flexDirection="column" alignItems="center">
                    <UserIcon style={{ fontSize: 60 }} />
                    <Typography style={{ marginTop: 10, marginBottom: 10 }} variant="h3">
                      {myClient.info.users}
                    </Typography>
                    <Typography variant="h6">USERS</Typography>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <Box sx={{ px: 6, py: 3 }} display="flex" flexDirection="column" alignItems="center">
                    <ClientIcon style={{ fontSize: 60 }} />
                    <Typography style={{ marginTop: 10, marginBottom: 10 }} variant="h3">
                      {myClient.info.children}
                    </Typography>
                    <Typography variant="h6">CLIENTS</Typography>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <Box sx={{ px: 6, py: 3 }} display="flex" flexDirection="column" alignItems="center">
                    <DeviceIcon style={{ fontSize: 60 }} />
                    <Typography style={{ marginTop: 10, marginBottom: 10 }} variant="h3">
                      {myClient.info.devices}
                    </Typography>
                    <Typography variant="h6">DEVICES</Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <pre>{JSON.stringify(myClient, null, 4)}</pre>

            {/* {myClients.map((client) => (
                  <Grid item xs={12} md={6} lg={3}>
                    <ClientCard {...client} />
                  </Grid>
                ))} */}
          </div>
        )}
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .super-app-theme--Online": {
      backgroundColor: theme.status.online,
      "&:hover": {
        backgroundColor: lighten(theme.status.online, 0.1),
      },
    },
  },
  appBar: {
    width: `calc(100% - ${Constants.drawerWidth}px)`,
    marginLeft: Constants.drawerWidth,
  },
  drawer: {
    width: Constants.drawerWidth,
    flexShrink: 0,
  },
  multiControl: {
    width: Constants.editorWidth,
    flexShrink: 0,

    // maxWidth: 400,
    // "& .MuiPaper-root": {
    //   zIndex: 10000000, // breaks selects...
    // },
  },
  deviceListLoader: {
    margin: theme.spacing(2),
  },
  drawerPaper: {
    width: Constants.editorWidth,
    backgroundColor: "#172026",
    color: "white",
  },
  editorDrawerPaper: {
    width: Constants.editorWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -Constants.editorWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey[400],
  },
  menuHeader: {
    color: "#bbb",
    // fontSize: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 240,
  },
  selectAdornment: {
    marginRight: 6, //theme.spacing(1), its 8
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
