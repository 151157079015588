import React from "react";
import constate from "constate";

function useSelection() {
  const [selection, setSelection] = React.useState([]);
  const update = (arr) => setSelection(arr);
  return { selection, update };
}

const [SelectionProvider, useSelectionContext] = constate(useSelection);

export { SelectionProvider, useSelectionContext };
