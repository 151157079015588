import React from "react";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
// import "chartjs-plugin-zoom";
import zoom from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import Hammer from "react-hammerjs";
import * as api from "../api";
import { useQuery } from "react-query";
import _ from "lodash";
import { Box, CircularProgress, Typography } from "@material-ui/core";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, zoom);

function getOptions({ unit }) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxis: {
        ticks: {
          callback: (v) => `${v} ${unit}`,
        },
        // title: {
        //   title: "Temp",
        //   color: "black",
        //   // title: `Temp (${unit})`,
        //   display: true,
        // },
        offset: true,
        // min: min,
        // max: max,
      },
      xAxis: {
        // The axis for this scale is determined from the first letter of the id as `'x'`
        // It is recommended to specify `position` and / or `axis` explicitly.
        type: "time",
        time: {
          // unit: "hour",
        },
      },
    },
    transitions: {
      zoom: {
        animation: {
          duration: 0,
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          enabled: true,
          mode: "x",
          wheel: {
            enabled: true,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (ctx) => {
            if (ctx.parsed.y !== null) {
              return `${ctx.dataset.label}: ${ctx.parsed.y} ${unit}`;
            }
            return ctx.dataset.label;
          },
        },
      },
      legend: {
        display: true,
        position: "top",
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Line Chart",
      // },
    },
  };
}

export function Chart1({ serial, options }) {
  const chartParams = ["Setpoint01", "TemperaturaCamara1"];
  const { isLoading, error, data } = useQuery(["param-history", serial], () => api.getParamHistory("", serial, chartParams));
  if (isLoading) {
    return (
      <Box sx={{ color: "#aaa" }} m={10} display="flex" justifyContent="center">
        <CircularProgress color="inherit" disableShrink />
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ textTransform: "uppercase" }} m={10} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography gutterBottom variant="h6" color="error">
          Error
        </Typography>
        <Typography variant="h10" color="error">
          Could not load chart data
        </Typography>
      </Box>
    );
  }

  // const min = _.get(data.result["Setpoint01"][0], "value.Setpoint01.min", -100);
  // const max = _.get(data.result["Setpoint01"][0], "value.Setpoint01.max", 100);
  const unit = _.get(data, "result.Setpoint01[0].value.Setpoint01.unit", ""); // Extract unit from first value of Setpoint01 array
  const formattedSetpoint01 = data.result["Setpoint01"].map((entry) => ({ x: new Date(entry.ts), y: entry.value["Setpoint01"].current }));
  const formattedTemp = data.result["TemperaturaCamara1"].map((entry) => ({ x: new Date(entry.ts), y: entry.value["TemperaturaCamara1"] }));

  let datasets = [
    {
      label: "Setpoint 1",
      data: formattedSetpoint01,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ];
  datasets.push({
    label: "Temperatura 1",
    data: formattedTemp,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  });
  return <Line style={{ height: 300 }} options={getOptions({ unit })} data={{ datasets }} />;
}

export function Chart2({ serial, options }) {
  const chartParams = ["Setpoint02", "TemperaturaCamara2"];
  const { isLoading, error, data } = useQuery(["param-history", serial], () => api.getParamHistory("", serial, chartParams));
  if (isLoading) {
    return (
      <Box sx={{ color: "#aaa" }} m={10} display="flex" justifyContent="center">
        <CircularProgress color="inherit" disableShrink />
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ textTransform: "uppercase" }} m={10} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography gutterBottom variant="h6" color="error">
          Error
        </Typography>
        <Typography variant="h10" color="error">
          Could not load chart data
        </Typography>
      </Box>
    );
  }

  // const min = _.get(data.result["Setpoint02"][0], "value.Setpoint02.min", -100);
  // const max = _.get(data.result["Setpoint02"][0], "value.Setpoint02.max", 100);
  const unit = _.get(data, "result.Setpoint02[0].value.Setpoint02.unit", ""); // Extract unit from first value of Setpoint02 array
  const formattedSetpoint02 = data.result["Setpoint02"].map((entry) => ({ x: new Date(entry.ts), y: entry.value["Setpoint02"].current }));
  const formattedTemp = data.result["TemperaturaCamara2"].map((entry) => ({ x: new Date(entry.ts), y: entry.value["TemperaturaCamara2"] }));

  let datasets = [
    {
      label: "Setpoint 1",
      data: formattedSetpoint02,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ];
  datasets.push({
    label: "Temperatura 2",
    data: formattedTemp,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  });
  return <Line style={{ height: 300 }} options={getOptions({ unit })} data={{ datasets }} />;
}
