import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { useEffect } from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    // margin: "auto",
    minWidth: 400,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const EnumValue = ({ label, value, options, onChange }) => {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={(event) => onChange(event.target.value)}>
        {options.map((entry, key) => (
          <MenuItem key={key} value={typeof entry == "object" ? entry.value : entry}>
            {typeof entry == "object" ? entry.display : entry}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const TextValue = ({ label, disabled, value, displayValue, onChange, onKeyUp }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      disabled={disabled}
      label={label}
      value={displayValue || value}
      onChange={handleChange}
      onKeyPress={(ev) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          onKeyUp(ev);
        }
      }}
    />
  );
};

const Field = ({ label, disabled, name, value, options, onChange }) => {
  // return (
  //   <div>
  //     <div>{name}</div>
  //     <pre>{value}</pre>
  //     <pre>{options}</pre>
  //   </div>
  // );

  if (options) {
    return <EnumValue label={label} value={value} options={options} onChange={onChange} />;
  }
  return <TextValue disabled={disabled} label={label} value={value} onChange={onChange} onKeyUp={() => console.log("581212")} />;
};

export const UserChanger = ({ user, fields, isOpen, onClose, onSave }) => {
  const classes = useStyles();
  const [newValue, setNewValue] = useState(null);

  useEffect(() => {
    if (user) {
      console.log(" ", JSON.stringify(user, null, 4));
      setNewValue({ ...user });
    }
  }, [isOpen]);

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      //   onSave(selected);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // onKeyUp={onKeyUp}
    >
      <DialogTitle>{user?.id ? "Editar usuário" : "Adicionar usuário"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate>
          {fields &&
            fields.map((f, key) => (
              <FormControl key={key} className={classes.formControl}>
                <Field
                  {...f}
                  key={key}
                  value={_.get(newValue, f.name)}
                  displayValue={_.get(newValue, f.path || f.name)}
                  onChange={(fieldValue) => {
                    console.log(`onChange: ${f.name}: ${fieldValue}`);
                    setNewValue({ ...newValue, [f.name]: fieldValue });
                  }}
                />
              </FormControl>
            ))}
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={false} onClick={() => onSave(newValue)} variant="contained" color="secondary">
          Salvar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
