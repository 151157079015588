import Axios from "axios";

const baseUrl = "http://4gglobusapi-env.eba-4dv2ma5h.us-east-2.elasticbeanstalk.com/device/";
// const baseUrl = "http://localhost:3000/device/";

const device = Axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    token: "uCoacpW9MYwTmLla56fZpsdQAS6wXTO4",
  },
});
device.defaults.withCredentials = false;

export const getAllDataById = async (id) => {
  const response = (await device.get(`allDataById/${id}`)).data;
  // .then((data) => console.log(data))
  // .catch((err) => console.log(err));
  return response;
};

export const findTemperatureById = async (id) => {
  const response = (await device.get(`temperatureById/${id}`)).data.then((data) => null).catch((err) => console.log(err));
  return response;
};

export const getSetpointById = async (id) => {
  const response = (await device.get(`setpointById/${id}`)).data;
  // .then((data) => console.log(data))
  // .catch((err) => console.log(err));
  return response;
};

export const getOperationModeById = async (id) => {
  const response = (await device.get(`operationModeById/${id}`)).data;
  // .then((data) => console.log(data))
  // .catch((err) => console.log(err));
  return response;
};

export const getBlowerSpeedById = async (id) => {
  const response = (await device.get(`blowerInfoById/${id}`)).data;
  // .then((data) => console.log(data))
  // .catch((err) => console.log(err));
  return response;
};

export const getLastLocationById = async (id) => {
  const response = (await device.get(`lastLocationById/${id}`)).data;
  //.then((data) => console.log(data))
  //.catch((err) => console.log(err));
  return response;
};

export const getTemperatureById = async (id) => {
  const response = (await device.get(`temperatureById/${id}`)).data;
  //.then((data) => console.log(data))
  //.catch((err) => console.log(err));
  return response;
};

export const getThermalControlCardInfoById = async (id) => {
  const response = (await device.get(`thermalControlCardInfoById/${id}`)).data;
  //.then((data) => console.log(data))
  //.catch((err) => console.log(err));
  return response;
};

export const getUpdatedAtById = async (id) => {
  const response = (await device.get(`updatedAtById/${id}`)).data;
  //.then((data) => console.log(data))
  //.catch((err) => console.log(err));
  return response;
};

export const getTemperatureHistoryById = async (id) => {
  const response = (await device.get(`temperatureHistoryById/${id}`)).data;
  //.then((data) => console.log(data))
  //.catch((err) => console.log(err));
  return response;
};
