import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { createStyles, createTheme, makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import * as React from "react";
import { Box } from "@material-ui/core";
import { grey, yellow } from "@material-ui/core/colors";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        fontWeight: "bold",
        borderRadius: 5,
      },
      Offline: {
        color: "#888",
        backgroundColor: `rgba(0,0,0,0.05)`,
      },
      Online: {
        color: theme.palette.success.main,
        backgroundColor: `rgba(0,255,0,0.1)`,
      },
      Pending: {
        color: yellow[800],
        backgroundColor: grey[100], //yellow[100],
      },
    }),
  { defaultTheme }
);

export const ChipStatus = ({ online }) => {
  const classes = useStyles();
  if (online) {
    return (
      <Box display="flex" alignItems="center" px={1} className={clsx(classes.root, classes.Online)}>
        ONLINE
      </Box>
    );
  }
  return (
    <Box display="flex" alignItems="center" px={1} className={clsx(classes.root, classes.Offline)}>
      OFFLINE
    </Box>
  );
};

export const ChipPending = ({ pending }) => {
  const classes = useStyles();
  if (pending) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" px={1} className={clsx(classes.root, classes.Pending)}>
        PENDING
      </Box>
    );
  }
  return <div />;
};
