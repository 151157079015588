import "./BarGraph.css";

export const BarGraph = ({ data, maxValue = 10, mode }) => {
  const _isAuto = data == 0 && mode != 0;
  const _value = _isAuto ? maxValue : data;

  let _width = [];

  for (let i = 0; i < maxValue; i++) {
    _width.push(i);
  }

  return (
    <div className="bar-graph" style={{ width: "60%", borderRadius: 6, borderColor: "#d9d9d9", borderWidth: 1, borderStyle: "solid" }}>
      {!_isAuto ? (
        _width.map((item, index) => {
          const color = item < _value ? "#D96B2B" : "#f2f2f2";
          return (
            <div
              className="bars"
              style={{
                backgroundColor: `${color}`,
                borderTopLeftRadius: index == 0 ? 6 : 0,
                borderBottomLeftRadius: index == 0 ? 6 : 0,
                borderTopRightRadius: index == maxValue - 1 ? 6 : 0,
                borderBottomRightRadius: index == maxValue - 1 ? 6 : 0,
                borderRightWidth: index != maxValue - 1 ? 2 : 0,
              }}
            ></div>
          );
        })
      ) : (
        <div className="bars" style={{ backgroundColor: "#D96B2B", borderRadius: 6 }}>AUTO</div>
      )}
    </div>
  );
};
