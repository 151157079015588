import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { useEffect } from "react";
import { Box, FormHelperText, TextField } from "@material-ui/core";
import _ from "lodash";
import * as yup from "yup";
import { useFormik } from "formik";
import * as api from "../../api";
import useClient from "../../hooks/useClient";
import { EnumValue } from "./EnumValue";

const validateUpdate = yup.object({
  name: yup.string("Enter a client name").required("Client name cannot be empty"),
});
const validateMove = yup.object({
  client: yup.string("Select a client for this user").required("Client is required"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    // margin: "auto",
    minWidth: 400,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const getBreadcrumbColor = (isLast) => (isLast ? grey[700] : grey[500]);

const toBreadcrumbs = (val) => {
  // return val.join("-");
  return (
    <Breadcrumbs>
      {val &&
        val.map((item, key) => (
          <Typography key={key} style={{ color: getBreadcrumbColor(key == val.length - 1) }}>
            {item}
          </Typography>
        ))}
    </Breadcrumbs>
  );
};

// Convert error "Django format" to formik
const errorToFormik = (resp) => {
  if (Array.isArray(resp)) {
    return {
      others: resp.join(", "),
    };
  }

  let output = {};
  for (const k of Object.keys(resp)) {
    output[k] = typeof resp[k] == "string" ? resp[k] + ", " : resp[k].join(", ");
  }

  if (output["username"] && typeof output["username"] == "string") {
    output["email"] = output["username"].replace("username", "email");
  }
  return output;
};

export const DialogClientChange = ({ options, initial, isOpen, onClose, onSave }) => {
  const classes = useStyles();
  //   const [clientOptions, setClientOptions] = useState(null);
  const clientOptions = options;

  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
    },
    validationSchema: validateUpdate,
    onSubmit: async (values, { setErrors }) => {
      try {
        if (initial.parent != values.parent) {
          await api.moveClient(values.slug, values.parent);
        }
        await api.clients().update(values);
        onClose();
      } catch (error) {
        console.log("got error", errorToFormik(error.response.data));
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (initial) {
      formik.setValues({ ...initial });
    }
  }, [isOpen]);

  const clientRemove = async (id) => {
    if (id == null) {
      formik.setErrors({ others: "Cliente não encontrado" });
      return;
    }

    try {
      await api.clients().delete({ id });
      onClose();
    } catch (error) {
      if (error?.response?.data?.message) {
        formik.setErrors({ others: error.response.data.message });
      }
    }
  };

  if (!clientOptions) return null; // for now

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // onKeyUp={onKeyUp}
    >
      <DialogTitle>{"Editar cliente"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="name"
            name="name"
            label="Nome"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          {formik.values.parent && (
            <EnumValue
              id="parent"
              name="parent"
              label="Cliente pai"
              options={clientOptions.map((c) => ({ value: c.slug, display: toBreadcrumbs([...c.pathNames, c.name]) }))}
              // options={clientOptions.map((c) => ({ value: c.slug, display: c.name }))}
              value={formik.values.parent}
              onChange={(value) => formik.setFieldValue("parent", value)}
              error={formik.touched.parent && Boolean(formik.errors.parent)}
              helperText={formik.touched.parent && formik.errors.parent}
            />
          )}
          {formik.errors.others && <FormHelperText error={true}>{formik.errors.others}</FormHelperText>}
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
          Atualizar
        </Button>
        <Button disabled={initial?.info?.children > 0 || initial?.info?.devices > 0} onClick={() => clientRemove(initial && initial.id)} color="secondary">
          Remover
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogClientCreate = ({ options, isOpen, onClose }) => {
  const classes = useStyles();
  //   const [clientOptions, setClientOptions] = useState(null);
  const clientOptions = options;
  const { client } = useClient();

  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
    },
    validationSchema: validateUpdate,
    onSubmit: async (values, { setErrors }) => {
      try {
        await api.clients().create(values);
        onClose();
      } catch (error) {
        console.log("got error");
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  if (!clientOptions) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // onKeyUp={onKeyUp}
    >
      <DialogTitle>{"Adicionar cliente"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="name"
            name="name"
            label="Nome"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <EnumValue
            id="parent"
            name="parent"
            label="Cliente pai"
            // options={clientOptions.map((c) => ({ value: c.parent.id, display: toBreadcrumbs(c.path) }))}
            // options={clientOptions.map((c) => ({ value: c.slug, display: toBreadcrumbs(c.pathNames) }))}
            options={clientOptions.map((c) => ({ value: c.slug, display: toBreadcrumbs([...c.pathNames, c.name]) }))}
            value={formik.values.parent}
            onChange={(value) => formik.setFieldValue("parent", value)}
            error={formik.touched.parent && Boolean(formik.errors.parent)}
            helperText={formik.touched.parent && formik.errors.parent}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
          Criar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
