import { Typography } from "@material-ui/core";
import React from "react";
import { useDashBoardAboutItem } from "./style";
import { getAllDataById, getUpdatedAtById } from "../../../api-globusway/api";
import { useTimeredGetRequest } from "../../../hooks/useTimeredRequest";

function SignalIcon({ size, signal, maxValue }) {
  let level = [
    Number.parseInt(maxValue * 0.2),
    Number.parseInt(maxValue * 0.4),
    Number.parseInt(maxValue * 0.6),
    Number.parseInt(maxValue * 0.8),
    Number.parseInt(maxValue),
  ];

  const ACTIVE_COLOR = "#D96B2B";
  const INACTIVE_COLOR = "#F0E3E3";

  const checkLevel = (input, target) => {
    if (input >= target) {
      return ACTIVE_COLOR;
    } else {
      return INACTIVE_COLOR;
    }
  };

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 400 400">
      <path fill={checkLevel(signal, level[0])} d="M13 266h41v105H13z" />
      <path fill={checkLevel(signal, level[1])} d="M97 205h41v166H97z" />
      <path fill={checkLevel(signal, level[2])} d="M180 142h41v229h-41z" />
      <path fill={checkLevel(signal, level[3])} d="M264 79h41v292h-41z" />
      <path fill={checkLevel(signal, level[4])} d="M347 17h41v354h-41z" />
    </svg>
  );
}

function AboutDevice({ serial, time }) {
  const styles = useDashBoardAboutItem();
  const apiResponse = useTimeredGetRequest(getUpdatedAtById, "b7fc4004-335d-4bc8-a98b-510367d45af9", 5);

  const _updatedAt = apiResponse.updatedAt ?  new Date(apiResponse.updatedAt) : new Date();
  const _signal = apiResponse.signal ? apiResponse.signal : 0;




  const updatedAt = _updatedAt.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour12: true,
    timeZone: 'America/New_York'
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoArea}>
        <Typography component="text" className={styles.infoDeviceName}>
          {serial}
        </Typography>
        <Typography component="text" className={styles.infoTimeText}>
          Update on
          <div>{updatedAt}</div>
        </Typography>
      </div>
      <div className={styles.actuatorsArea}>
        <div style={{ border: "1px solid white", borderRadius: 10, padding: 10 }}>
          <SignalIcon size={45} signal={_signal} maxValue={32} />
          <div className={styles.infoTimeText} style={{ justifyContent: "flex-start", display: "flex" }}>
            {_signal}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutDevice;
