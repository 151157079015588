import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import env from "../env";

import axios from "axios";
//import Cookies from "js-cookie";

import Copyright from "../components/Copyright";
import background_default from "../assets/images/login_bg.jpg";
import background_fk from "../assets/images/brands/banner_fk.jpg";
import roundLogo from "../assets/images/round_logo.png";
import smallLogo_fk from "../assets/images/brands/brand_frigoking_login.png";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";

import * as api from "../api";
import { useAuthContext } from "../hooks/useAuth";
import { getAllDataById } from "../api-globusway/api";

const background = env.Client == "FK" ? background_fk : background_default;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(" + background + ")",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(3),
    height: 80,
    width: 80,
  },
  banner_fk: {
    margin: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const StyledButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #ff6d00 30%, #ff9100 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px #eee",
  },
  label: {},
})(Button);

export default function Login() {
  const classes = useStyles();
  let history = useHistory();
  const { register, handleSubmit, errors, clearErrors, setError } = useForm();
  const [nonFieldError, setNonFieldError] = useState("");
  const { setLogin } = useAuthContext();
  const pageAfterLogin = env.Client == "FK" ? "/fk/device" : "/device";

  const onSubmit = (data) => {
    clearErrors();
    setNonFieldError("");
    // console.log("onsubmit", data);
    // - bypass to develop

    if (data.username == "admin" && data.password == "@globus123") {
      setLogin({ token: "13", username: "admin" });
      history.push("/device/GL_MACS");
    }

    // setLogin({ token: "12",username: 'Admin' });
    // --------

    // api
    //   .login(data)
    //   .then((resp) => {
    //     if (resp.data.token) {
    //       console.log("Login.jsx", resp.data);
    //       setLogin({ ...resp.data, impersonate: resp.data.client });
    //       // Cookies.set("token", resp.data.token);
    //       history.push(pageAfterLogin);
    //       // window.location.reload(); // initial page with token
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response && error.response.data) {
    //       Object.keys(error.response.data).forEach((key) => {
    //         if (key === "non_field_errors") {
    //           setNonFieldError(error.response.data[key][0]);
    //         } else {
    //           setError(key, { message: error.response.data[key] });
    //         }
    //       });
    //     } else {
    //       setNonFieldError(error.toString());
    //     }
    //   });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {env.Client == "Default" && <Avatar className={classes.avatar} src={roundLogo} />}
          {env.Client == "FK" && <img className={classes.banner_fk} src={smallLogo_fk} />}
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit, (e) => console.log("invalid", e))} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              inputRef={register}
              error={errors.username}
              helperText={errors.username && errors.username.message}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={register}
              error={errors.password}
              helperText={errors.password && errors.password.message}
            />
            {false && <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />}
            <StyledButton type="submit" fullWidth variant="contained" className={classes.submit}>
              Entrar
            </StyledButton>
            {nonFieldError && <div style={{ color: "red" }}>{nonFieldError}</div>}
            <Grid justifyContent="center" container>
              <Grid item>
                {/* <Link href="/reset-password-1" variant="body2"> */}
                  Esqueceu sua senha?
                {/* </Link> */}
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
