import { differenceInMinutes, parseISO } from "date-fns";
import _ from "lodash";

export function getInitiais(info) {
  const { name, email, phone } = info;
  if (name) {
    const words = name.trim().split(" ");
    if (words.length == 1) {
      return words[0].slice(0, 1).toUpperCase();
    } else {
      const first_name = words[0];
      const last_name = words[words.length - 1];
      const initials = first_name.slice(0, 1) + last_name.slice(0, 1);
      return initials.toUpperCase();
    }
  } else if (email) {
    return email.trim().slice(0, 1).toUpperCase();
  }
  return null;
}

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

export const enumToBoolean = (value) => {
  try {
    const options = _.get(value, "options");
    const selected = _.get(value, "selected", 0);
    return options[selected] == "Habilita" || options[selected] == "Enable";
  } catch (error) {
    return false;
  }
};

export const decodeSetpoint = (props) => {
  const { updated_at, params } = props;
  const { EstadoControle, HabilitaSensorTemperaturaCamara2, Setpoint01, Setpoint02, TemperaturaCamara1, TemperaturaCamara2 } = params;

  const chamber2 = enumToBoolean(HabilitaSensorTemperaturaCamara2);
  const isOnline = differenceInMinutes(new Date(), parseISO(updated_at)) <= 1;
  const setpoint1 = Setpoint01 == null ? "--" : _.get(Setpoint01, "current") + " " + _.get(Setpoint01, "unit");
  const setpoint2 = Setpoint02 == null ? "--" : _.get(Setpoint02, "current") + " " + _.get(Setpoint02, "unit");
  const temp1 = TemperaturaCamara1 == null ? "--" : TemperaturaCamara1 + " " + _.get(Setpoint01, "unit");
  const temp2 = TemperaturaCamara2 == null ? "--" : TemperaturaCamara2 + " " + _.get(Setpoint02, "unit");
  const controle = _.get(EstadoControle, "description", "\u00A0");

  return {
    chamber2,
    isOnline,
    setpoint1,
    setpoint2,
    temp1: temp1 || "--",
    temp2: temp2 || "--",
    controle,
  };
};

export const normalizeValue = (val) => {
  if (val == null) return null;
  const num = Number(val);
  if (Number.isNaN(num)) return val;
  return num;
};
