import { makeStyles } from "@material-ui/core";

export const useThermalControlCardStyle = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
  },

  container: {
    display: "flex",
    flex: 1,
    boxShadow: "1px 1px 5px 1px #5A6973",
    backgroundColor: "#fefefe",
    borderRadius: 6,
    flexDirection: "column",
    height: "100%",
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },

  cardTitle: {
    color: "#5A6973",
    fontSize: 18,
    wordWrap: "break-word",
  },

  thermalInfoContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    justifyContent: "center",
  },

  thermalInfoTitleText: {
    color: "#5A6973",
    fontSize: 18,
    fontWeight: "500",
    wordWrap: "break-word",
  },

  thermalInfoDataText: {
    color: "#D96B2B",
    fontWeight: 500,
    fontSize: 25,
  },

  thermalInfoBarGraphContainer: {
    height: 25,
    backgroundColor:'#fefefe',
    borderRadius: 6,
    marginTop: 10,
    marginBottom: 10,
    width: '70%',
    borderColor: '#D96B2B',
    borderWidth: 1,
    borderStyle: 'solid',
  },


  thermalInfoBarGraphContainer2: {
    height: 25,
    backgroundColor:'red',
    borderRadius: 6,
    marginTop: 10,
    marginBottom: 10,
    width: '80%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
  },

  internalTemperatureContainer: {
    backgroundColor: "#fefefe",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },

  internalTemperatureText: {
    color: "#D96B2B",
    fontWeight: 500,
    fontSize: 50,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: -30,
  },

  internalTemperatureSubTitle: {
    color: "#5A6973",
    fontSize: 20,
    fontWeight: "500",
    marginTop: -10,
  },
}));
