import React from "react";
import { useOperationStatusStyle } from "./styles";

function OperationStatus({ serial, status = 0 }) {
  const styles = useOperationStatusStyle();
  

  const operationStatusTexts = ["Stand-by ", "Refrigerando", "Degelo", "Gotejamento"];
  let operationStatusText;

  if (status < 0 || status > operationStatusTexts.length - 1) operationStatusText = "--";
  else operationStatusText = operationStatusTexts[status];

  return (
    <div className={styles.wrapper}>
      <div className={styles.textArea}>
        <div className={styles.title}>Operation Status</div>
        <div className={styles.subtitle}>{operationStatusText}</div>
      </div>
      <div className={styles.activeFunctionArea}>
        {/* <div style={{ width: 65, height: 65, background: "#F27B35", borderRadius: 9999 }} /> */}
      </div>
    </div>
  );
}

export default OperationStatus;
