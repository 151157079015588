import React, { useEffect } from "react";
import constate from "constate";

const emptyState = {
  username: "Anonymous",
  impersonate: null,
  client: "",
  email: "",
  first_name: "",
  last_name: "",
  token: "",
};

const initialState = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : { ...emptyState };

function useAuth() {
  const [auth, setAuth] = React.useState({ ...initialState });
  const setLogin = (data) => {
    console.log("setAuth");
    setAuth((s) => ({ ...s, ...data }));
  };
  const setImpersonate = (client) => {
    console.log("setImpersoante", client);
    setAuth((s) => ({ ...s, impersonate: client }));
  };
  const setClear = () => setAuth({ ...initialState });

  useEffect(() => {
    console.log(">>> AUTH", auth);
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  return { auth, setClear, setLogin, setImpersonate };
}

const displayName = (auth) => {
  const { first_name, last_name, username } = auth;
  const fullname = `${first_name || ""} ${last_name || ""}`.trim();
  if (fullname.length > 0) {
    return fullname;
  }
  return username;
};

const avatar = (auth) => {
  const { first_name, last_name, username } = auth;
  if (first_name != "" || last_name != "") {
    const initials = first_name.slice(0, 1) + last_name.slice(0, 1);
    return initials.toUpperCase();
  }
  return username.slice(0, 1).toUpperCase();
};

const [AuthProvider, useAuthContext] = constate(useAuth);

export { AuthProvider, useAuthContext, displayName, avatar };
