import { Menu, withStyles } from "@material-ui/core";
import React from "react";
import Constants from "./Constants";

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    marginTop: 10,
    minWidth: Constants.drawerWidth,
  },
})((props) => (
  <Menu
    elevation={3}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    TransitionProps={{
      timeout: 0,
    }}
    MenuListProps={{
      style: {
        // paddingBottom: 0, // Single item doesnt not look ok with padding 0
      },
    }}
    {...props}
  />
));
