import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { lighten, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import * as api from "../api";
import ClientBreadcrumbs from "../components/ClientBreadcrumbs";
import { Drawer as MenuLateral } from "../components/Drawer";
import useClient from "../hooks/useClient";

const validationSchema = yup.object({
  name: yup.string("Enter a client name").required("Name is required"),
});

const NewClientForm = ({ parent }) => {
  const { isLoading, isError, error, data } = useQuery("client-tree", api.clientTree);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const response = await api.clients().create({ ...values, parent });
        // const response = await axios.get('/user?ID=12345');
        console.log(response);
      } catch (error) {
        setErrors({ name: error.response.data });
      }
    },
  });

  return (
    <div>
      <Box mt={1} mb={1}>
        <ClientBreadcrumbs />
      </Box>
      <Typography variant="h4" component="h4" gutterBottom>
        Create client for <b>{parent}</b>
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Client's name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <Button color="primary" variant="contained" fullWidth type="submit">
          Create
        </Button>
      </form>
    </div>
  );
};

export default function Device() {
  const classes = useStyles();
  let { client } = useClient();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuLateral />
      <Box m={3}>
        <NewClientForm parent={client.slug} />
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .super-app-theme--Online": {
      backgroundColor: theme.status.online,
      "&:hover": {
        backgroundColor: lighten(theme.status.online, 0.1),
      },
    },
  },
}));
