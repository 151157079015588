const MAGIC_TEMP_NUMBER = 4597.0;

export function toCelsius(f) {
  return (5 / 9) * (f - 32);
}
export function setpointToCelsius(val) {
  const f = (val - MAGIC_TEMP_NUMBER) / 10.0;
  return Math.round(toCelsius(f)); // integer
}

export function toFahrenheit(c) {
  return c * (9 / 5) + 32;
}

export function celsiusToSetpoint(c) {
  return toFahrenheit(c) * 10.0 + MAGIC_TEMP_NUMBER;
}
