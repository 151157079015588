import { makeStyles } from "@material-ui/core";

export const useDashBoardDeviceItemStyle = makeStyles((theme) => ({
  mainGrid: {
    padding: 8,
    maxWidth:300
  },

  actionArea: {
    height: "100%",
  },

  internalCard: {
    backgroundColor: "#f2f2f2",
    boxShadow: "1px 1px 5px 1px #5A6973",
    borderRadius: 6,
  },
  internalBox: {
    flexDirection: "row",
    display: "flex",
  },

  statusContainer: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
  },

  deviceNameContainer: {
    paddingLeft: 20,
    flex: 5,
  },

  deviceNameText: {
    color: "#383C3F",
    marginTop: 20,
  },

  subInfoText: {
    color: "#5A6973",
    fontSize: 15,
    fontWeight: "normal",
  },

  bottomDeviceNameContainerBar: {
    backgroundColor: "#D9D9D9",
    flex: 1,
    width: "110%",
  },

  temperatureContainer: {
    display: "flex",
    flex: 3,
    flexDirection: "column",
  },

  truckArea: {
    height: "64%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  truckContainer: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: "2%",
  },

  speedBumpView: {
    width: "100%",
    height: "40%",
    display: "flex",
    paddingLeft: "38%",
    paddingTop: "3%",
    paddingBottom: "5%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#D9D9D9",
    clipPath: "polygon(20% 00%, 100% 0%, 100% 100%, 0 100% , 0  60% )",
  },

  setpointValueText: {
    fontWeight: "normal",
    fontSize: 20,
    color: "#5A6973",
  },
}));
