import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useEffect } from "react";
import { Box, TextField } from "@material-ui/core";
import _ from "lodash";
import * as yup from "yup";
import { useFormik } from "formik";
import * as api from "../../api";
import useClient from "../../hooks/useClient";
import { EnumValue } from "./EnumValue";
import { toBreadcrumbs } from "./Breadlist";
import EmbedTable from "./EmbedTable";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    // margin: "auto",
    minWidth: 400,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const validateUpdate = yup.object({
  email: yup.string("Enter an email address").required("Email is required"),
  first_name: yup.string("Enter a first name").required("First name is required"),
  last_name: yup.string("Enter a last name").required("Last name is required"),
  client: yup.string("Select a client for this user").required("Client is required"),
  fk_level: yup.string("Select access level for this user").required("Access level is required"),
});
const validateCreate = yup.object({
  email: yup.string("Enter an email address").required("Email is required"),
  password: yup.string().required("Please Enter your password").min(6, "Minimum 6 characters"),
  first_name: yup.string("Enter a first name").required("First name is required"),
  last_name: yup.string("Enter a last name").required("Last name is required"),
  client: yup.string("Select a client for this user").required("Client is required"),
  fk_level: yup.string("Select access level for this user").required("Access level is required"),
});
const validateMove = yup.object({
  client: yup.string("Select a client for this user").required("Client is required"),
});

// Convert error "Django format" to formik
const errorToFormik = (resp) => {
  let output = {};
  for (const k of Object.keys(resp)) {
    output[k] = resp[k] ? resp[k].join(", ") : "--";
  }
  output["email"] = output["username"].replace("username", "email");
  return output;
};

export const DialogUserChange = ({ user, isOpen, onClose, onSave }) => {
  const classes = useStyles();
  const [newValue, setNewValue] = useState(null);
  // const [clientOptions, setClientOptions] = useState(null);
  const { client, clientOptions } = useClient();

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      client: "", // id
      fk_level: "", // string
    },
    validationSchema: validateUpdate,
    onSubmit: async (values, { setErrors }) => {
      try {
        const newUser = { ...values, parent: values.client, username: values.email };
        const response = await api.users().update(newUser);
        onClose();
      } catch (error) {
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (user) {
      // console.log(" ", JSON.stringify(user, null, 4));
      // setNewValue({ ...user });
      formik.setValues({ ...user });
    }
  }, [isOpen]);

  if (!clientOptions) return null; // for now

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // onKeyUp={onKeyUp}
    >
      <DialogTitle>{"Editar usuário"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            disabled={true}
            margin="normal"
            fullWidth
            autoComplete="off"
            id="email"
            name="email"
            label="Email/username"
            value={formik.values.email || formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="first_name"
            name="first_name"
            label="First name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />

          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="last_name"
            name="last_name"
            label="Last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />

          <EnumValue
            id="fk_level"
            name="fk_level"
            label="Acesso"
            options={["USER", "ADM", "AST", "FK"]}
            value={formik.values.fk_level}
            onChange={(value) => formik.setFieldValue("fk_level", value)}
            error={formik.touched.fk_level && Boolean(formik.errors.fk_level)}
            helperText={formik.touched.fk_level && formik.errors.fk_level}
          />

          <EnumValue
            id="client"
            name="client"
            label="Cliente"
            options={clientOptions.map((c) => ({ value: c.client.id, display: toBreadcrumbs(c.path) }))}
            value={formik.values.client}
            onChange={(value) => formik.setFieldValue("client", value)}
            error={formik.touched.client && Boolean(formik.errors.client)}
            helperText={formik.touched.client && formik.errors.client}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
          Atualizar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogUserCreate = ({ user, fields, isOpen, onClose, onSave }) => {
  const classes = useStyles();
  const { client, clientOptions } = useClient();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      client: "", // id
      fk_level: "", // string
    },
    validationSchema: validateCreate,
    onSubmit: async (values, { setErrors }) => {
      try {
        const newUser = { ...values, parent: values.client, username: values.email };
        console.log("create", newUser);
        const response = await api.users().create(newUser);
        console.log(response);
        onClose();
        // TODO: close form ? return to list ?
      } catch (error) {
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  if (!clientOptions) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // onKeyUp={onKeyUp}
    >
      <DialogTitle>{"Adicionar usuário"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="email"
            name="email"
            label="User's email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            autoComplete="new-password"
            id="password"
            type="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="first_name"
            name="first_name"
            label="First name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />

          <TextField
            margin="normal"
            fullWidth
            autoComplete="off"
            id="last_name"
            name="last_name"
            label="Last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />

          <EnumValue
            id="fk_level"
            name="fk_level"
            label="Acesso"
            options={["USER", "ADM", "AST", "FK"]}
            value={formik.values.fk_level}
            onChange={(value) => formik.setFieldValue("fk_level", value)}
            error={formik.touched.fk_level && Boolean(formik.errors.fk_level)}
            helperText={formik.touched.fk_level && formik.errors.fk_level}
          />

          <EnumValue
            id="client"
            name="client"
            label="Cliente"
            options={clientOptions.map((c) => ({ value: c.client.id, display: toBreadcrumbs(c.path) }))}
            value={formik.values.client}
            onChange={(value) => formik.setFieldValue("client", value)}
            error={formik.touched.client && Boolean(formik.errors.client)}
            helperText={formik.touched.client && formik.errors.client}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
          Criar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogBulkMove = ({ isOpen, userList, onClose }) => {
  const classes = useStyles();
  const { clientOptions } = useClient();
  const [status, setStatus] = useState("initial");
  const [localUserList, setLocalUserList] = useState([]);

  const updateSingleUser = (id, status) => setLocalUserList((prev) => prev.map((u) => (u.id === id ? { ...u, updateStatus: status } : u)));

  const formik = useFormik({
    initialValues: {
      client: "", // id
    },
    validationSchema: validateMove,
    onSubmit: async (values, { setErrors }) => {
      try {
        setStatus("update");
        for (const user of userList) {
          try {
            const updatedUser = { ...user, parent: values.client, client: values.client };
            await api.users().update(updatedUser);
            updateSingleUser(user.id, "success");
          } catch (error) {
            updateSingleUser(user.id, "error");
          }
        }
        setStatus("done");
      } catch (error) {
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setStatus("initial");
      setLocalUserList([...userList.map((u) => ({ ...u, updateStatus: "pending" }))]);
    }
  }, [isOpen, userList]);

  if (!clientOptions) return null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{`Mover ${userList?.length} usuários`}</DialogTitle>
      <DialogContent>
        <Box mb={2} style={{ maxHeight: 300, overflow: "scroll" }}>
          <EmbedTable
            cols={[
              { label: "Usuário", path: "username" },
              { label: "Cliente atual", path: "client_detail.name" },
              { label: "Status", path: "updateStatus", render: (status) => (status === "pending" ? "Pendente" : status === "error" ? "Erro" : "Sucesso") },
            ]}
            rows={localUserList}
          />
        </Box>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <EnumValue
            disabled={status != "initial"}
            id="client"
            name="client"
            label="Novo cliente"
            options={clientOptions.map((c) => ({ value: c.client.id, display: toBreadcrumbs(c.path) }))}
            value={formik.values.client}
            onChange={(value) => formik.setFieldValue("client", value)}
            error={formik.touched.client && Boolean(formik.errors.client)}
            helperText={formik.touched.client && formik.errors.client}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        {status == "initial" && (
          <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
            Mover
          </Button>
        )}
        {status !== "initial" && (
          <Button disabled={status == "update"} onClick={onClose} variant="contained" color="secondary">
            {status == "done" ? "Fechar" : "Atualizando"}
          </Button>
        )}
        {status == "initial" && (
          <Button disabled={status == "update"} onClick={onClose} color="primary">
            Cancelar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const DialogBulkRemove = ({ isOpen, userList, onClose }) => {
  const classes = useStyles();
  const { clientOptions } = useClient();
  const [status, setStatus] = useState("initial");
  const [localUserList, setLocalUserList] = useState([]);

  const updateSingleUser = (id, status) => setLocalUserList((prev) => prev.map((u) => (u.id === id ? { ...u, updateStatus: status } : u)));

  const deleteBulk = async () => {
    try {
      setStatus("update");
      for (const user of userList) {
        try {
          await api.users().delete(user);
          updateSingleUser(user.id, "success");
        } catch (error) {
          updateSingleUser(user.id, "error");
        }
      }
      setStatus("done");
    } catch (error) {
      console.log("Error ASKLDJAS", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setStatus("initial");
      setLocalUserList([...userList.map((u) => ({ ...u, updateStatus: "pending" }))]);
    }
  }, [isOpen, userList]);

  if (!clientOptions) return null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{`Apagar ${userList?.length} usuários`}</DialogTitle>
      <DialogContent>
        <Box mb={2} style={{ maxHeight: 300, overflow: "scroll" }}>
          <EmbedTable
            cols={[
              { label: "Usuário", path: "username" },
              { label: "Cliente atual", path: "client_detail.name" },
              { label: "Status", path: "updateStatus", render: (status) => (status === "pending" ? "Pendente" : status === "error" ? "Erro" : "Sucesso") },
            ]}
            rows={localUserList}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        {status == "initial" && (
          <Button disabled={false} onClick={deleteBulk} variant="contained" color="secondary">
            Remover
          </Button>
        )}
        {status !== "initial" && (
          <Button disabled={status == "update"} onClick={onClose} variant="contained" color="secondary">
            {status == "done" ? "Fechar" : "Atualizando"}
          </Button>
        )}
        {status == "initial" && (
          <Button disabled={status == "update"} onClick={onClose} color="primary">
            Cancelar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
