import _ from "lodash";
import { deepOrange } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DeviceControl from "../components/DeviceControl/DeviceControl";
import { Drawer as MenuLateral, Constants } from "../components/Drawer";
import AlertIcon from "@material-ui/icons/ReportProblemOutlined";
import Switch from "@material-ui/core/Switch";
import { parseISO, formatDistanceToNow } from "date-fns";
import differenceInMinutes from "date-fns/differenceInMinutes";
//import Cookies from "js-cookie";

import { setpointToCelsius, celsiusToSetpoint } from "../common/convert";

import * as api from "../api";
import MenuTree from "../components/MenuTree";
import DeviceCard from "../components/DeviceCard";
import { createTheme, darken, lighten } from "@material-ui/core/styles";
import { usePaginatedQuery, useQuery } from "react-query";
import { DataGrid } from "@material-ui/data-grid";
// import { XGrid as DataGrid } from "@material-ui/x-grid";
import constate from "constate";
import Drawer from "@material-ui/core/Drawer";
import { renderOnline } from "./renderOnline";
import { renderEnum } from "./renderEnum";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { InputAdornment } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useLayoutEffect } from "react";
import { useEnhancedEffect } from "@material-ui/data-grid";
import { difference } from "../common/difference";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import DeviceAppBar from "../components/DevicesAppBar";

import clsx from "clsx";
import { protocolToProject } from "../common/projects";

import { SelectionProvider, useSelectionContext } from "../hooks/useSelectionContext";
import { useAuthContext } from "../hooks/useAuth";

/*
client
created_at
params_changed_at
protocol
read_params
serial
updated_at
write_params*/

let baseColumns = [
  {
    field: "isOnline",
    headerName: "Status",
    renderCell: renderOnline,
    flex: 1,
  },
  // {
  //   field: "lastRead",
  //   headerName: "Device last seen",
  //   flex: 1,
  // },
  // {
  //   field: "readPending",
  //   headerName: "Sync",
  //   flex: 1,
  // },
  {
    field: "id",
    headerName: "Serial",
    flex: 1,
  },
  // {
  //   field: "lastChange",
  //   headerName: "Last change",
  //   flex: 1,
  // },
];

const optionsOnOff = [
  { value: 0, icon: "Level_0of1", label: "Desl." },
  { value: 1, icon: "Level_1of1", label: "Ligado" },
];

const options3levels = [
  { value: 0, icon: "Level_0of2", label: "Desl." },
  { value: 1, icon: "Level_1of2", label: "Média" },
  { value: 2, icon: "Level_2of2", label: "Alta" },
];

const options4levels = [
  { value: 0, icon: "Level_0of3", label: "Desl." },
  { value: 1, icon: "Level_1of3", label: "Baixa" },
  { value: 2, icon: "Level_2of3", label: "Média" },
  { value: 3, icon: "Level_3of3", label: "Alta" },
];

// const speedParser = (value) => {
//   // return value;
//   const result = options4levels.find((entry) => entry.value === value);
//   console.log("speedparser", value, result);
//   return result;
// };

// const ParamText = ({ field, value }) => {
//   return (
//     <TextField
//       id="standard-number"
//       label={field}
//       value={value}
//       // onChange={handleChange}
//       InputProps={{
//         readOnly: true, // user must click to enable editing..?
//       }}
//       InputLabelProps={
//         {
//           // shrink: true,
//         }
//       }
//       // InputProps={{ inputProps: { min: 0, max: 10 } }}
//     />
//   );
// };

const ParamNumber = ({ field, headerName, value, unit, onChange, readOnly }) => {
  const classes = useStyles();

  let InputProps = {
    readOnly: readOnly, // user must click to enable editing..?
  };
  if (unit) {
    InputProps["endAdornment"] = (
      <InputAdornment className={classes.selectAdornment} position="end">
        {unit}
      </InputAdornment>
    );
  }
  // TODO: if min max... ?

  return (
    <TextField
      fullWidth
      id="standard-number"
      label={headerName}
      type="number"
      value={value || ""}
      onChange={(event) => onChange(field, event)}
      InputProps={InputProps}
      // InputLabelProps={
      //   {
      //     // shrink: true,
      //   }
      // }
      // InputProps={{ inputProps: { min: 0, max: 10 } }}
    />
  );
};

const ParamEnum = ({ field, headerName, value, options, onChange }) => {
  const classes = useStyles();
  let valueNumber = value != null ? value : -1;

  // if (value != null && typeof value == "object") {
  //   valueNumber = value.value;
  // }

  return (
    <FormControl fullWidth style={{ margin: 8 }} className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">{headerName}</InputLabel>
      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={valueNumber} onChange={(event) => onChange(field, event)}>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const valueFormatter = (x) => {
  // console.log("valueformatter", x.value, options3levels[x.value]);
  return optionsOnOff[x.value];
};

const valueParser = (x) => {
  // console.log("valueParser", x);
  return x;
};

// prettier-ignore
const systemParameters = [ // IMPL
  { field: "Setpoint",    renderSide: ParamNumber, headerName: "Setpoint",         unit: "°C", kind: "number", format: (n) =>  Math.round(setpointToCelsius(n)), output: (n) => celsiusToSetpoint(n)}, // TODO: max min etc?//  + " °C" 
  // { field: "Tensao_da_Bateria",         renderSide: ParamNumber, headerName: "Bateria",          unit: "V",   readOnly: true, kind: "number", format:  (v) => (v/10)},
  { field: "Limpador_Dianteiro",        valueParser: valueParser, renderSide: ParamEnum, headerName: "Limp. Dianteiro",    kind: "select", renderCell: renderEnum, valueOptions: optionsOnOff},
  { field: "Limpador_Traseiro",         renderSide: ParamEnum, headerName: "Limp. Traseiro" ,    kind: "select", renderCell: renderEnum, valueOptions: optionsOnOff},
  { field: "Esguicho_de_Agua",          renderSide: ParamEnum, headerName: "Esguicho de Água" ,  kind: "select", renderCell: renderEnum, valueOptions: optionsOnOff},
  { field: "Farol_Dianteiro",           renderSide: ParamEnum, headerName: "Farol Dianteiro" ,   kind: "select", renderCell: renderEnum, valueOptions: options3levels}, // TODO: na verdade depende de um parametro
  { field: "Farol_Traseiro",            renderSide: ParamEnum, headerName: "Farol Traseiro" ,    kind: "select", renderCell: renderEnum, valueOptions: options3levels},
  { field: "Velocidade_da_Ventilacao",  renderSide: ParamEnum, headerName: "Ventilação",         kind: "select", renderCell: renderEnum, valueOptions: options4levels},
];

// prettier-ignore
const FKsystemParameters = [
  { kind: "number", renderSide: ParamNumber, field: "Setpoint01",                 headerName: "Setpoint 01" },
  { kind: "number", renderSide: ParamNumber, field: "Setpoint02",                 headerName: "Setpoint 02" },
  { kind: "number", renderSide: ParamNumber, field: "ValorMaximoDoSetpoint",      headerName: "Valor maximo do setpoint" },
  { kind: "number", renderSide: ParamNumber, field: "ValorMinimoDoSetpoint",      headerName: "Valor minimo do setpoint" },
  { kind: "number", renderSide: ParamNumber, field: "OffSetSensorCamara1",        headerName: "Off-set sensor câmara 1" },
  // { kind: "number", renderSide: ParamNumber, field: "OffsetSensorDeSuccao",       headerName: "Offset sensor de sucção" },
  // { kind: "number", renderSide: ParamNumber, field: "OffSetSensorCamara2",        headerName: "Off-set sensor câmara 2" },
  // { kind: "number", renderSide: ParamNumber, field: "OffSetDoSensorDeSerpentina", headerName: "Off-set do sensor de serpentina" },
  { kind: "select", renderSide: ParamEnum, renderCell: renderEnum, valueOptions: optionsOnOff, field: "HabilitaSensorDeSerpentina",        headerName: "Habilita Sensor de serpentina" },
  { kind: "select", renderSide: ParamEnum, renderCell: renderEnum, valueOptions: optionsOnOff, field: "HabilitaSensorDePorta",             headerName: "Habilita sensor de porta" },
  { kind: "select", renderSide: ParamEnum, renderCell: renderEnum, valueOptions: optionsOnOff, field: "HabilitaSensorTemperaturaCamara2",  headerName: "Habilita sensor temperatura camara 2" },
  { kind: "select", renderSide: ParamEnum, renderCell: renderEnum, valueOptions: optionsOnOff, field: "HabilitaLogSomenteApolloEFlex",  headerName: "Habilita log" },
];

const convert = (rows) => {
  let output = [];

  for (const device of rows) {
    const { serial, protocol, updated_at, client, read_params, write_params, params_changed_at, device_accessed_at } = device;

    const lastChange = formatDistanceToNow(parseISO(params_changed_at));
    const lastRead = formatDistanceToNow(parseISO(device_accessed_at));
    const isOnline = differenceInMinutes(new Date(), parseISO(device_accessed_at)) <= 1 ? "Online" : "Offline"; // todo: parametrizar?
    const readPending = write_params && write_params.length > 0 ? "Read pending" : "Sync"; // Read is pending when there are write_params
    let formattedRow = { id: serial, protocol, isOnline, lastChange, lastRead, readPending: readPending };
    let params;

    // let setpoint = "Not avail.";
    if (read_params && read_params.length > 0) {
      try {
        params = JSON.parse(read_params);
        for (const { field, headerName, editable, format, valueOptions } of systemParameters) {
          let value = "Not avail.";
          let fieldPresent = field;

          // // List of possible field names: stop at
          // if (Array.isArray(field)) {
          //   for (const alt of field) {
          //     if (alt in params) {
          //       fieldPresent = alt;
          //       continue;
          //     }
          //   }
          // } else {
          //   fieldPresent = field;
          // }
          if (fieldPresent && fieldPresent in params) {
            const numericValue = params[fieldPresent];
            if (valueOptions) {
              // TODO: mudar pra inteiro novamente na escrita
              if (numericValue >= 0 && numericValue < valueOptions.length) {
                value = valueOptions[numericValue];
              } else {
                value = "Out of range"; // Cannot index valueOptions with value
              }
            } else if (format) {
              value = format(numericValue);
            } else {
              value = numericValue;
            }
          }
          formattedRow[fieldPresent] = value;
        }
        // if ("Setpoint" in params) {
        //   setpoint = Math.round(setpointToCelsius(params["Setpoint"])) + "°C";
        // }
      } catch (error) {
        console.log("Could not parse json", read_params);
      }
    }
    // console.log(JSON.stringify(device, null, 4));

    // output.push({ original: params, formatted: formattedRow });
    output.push(formattedRow);
  }

  return output;
};

const DeviceList = ({ devices, onlineOnly, multiSelectionMode }) => {
  // const [selectionModel, setSelectionModel] = React.useState([])
  const { selection, update } = useSelectionContext();
  // console.table(rows);
  // return <div>{JSON.stringify(cleanData)}</div>;
  let columns = [...baseColumns];
  for (const { field, headerName, valueOptions, valueParser, valueFormatter, editable, renderCell, readOnly } of systemParameters) {
    const columnConfig = { flex: 1, field, headerName, editable, renderCell, readOnly, valueParser, valueFormatter };
    if (valueOptions) {
      columnConfig.type = "singleSelect";
      columnConfig.valueOptions = valueOptions;
      // columnConfig.valueParser = valueParser;
    }
    columns.push(columnConfig);
  }

  // console.log({ columns });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const filterModel = {
    items: [{ columnField: "isOnline", operatorValue: "equals", value: "Online" }],
  };
  const emptyFilter = {
    items: [],
  };
  const activeFilters = onlineOnly ? filterModel : emptyFilter;

  const rows = devices;
  console.log({ rows, columns });
  // const rows = convert(data);
  return (
    <div>
      {/* <DataGrid
        filterModel={activeFilters}
        autoHeight
        rows={devices}
        columns={columns}
        disableColumnMenu={true}
        checkboxSelection={multiSelectionMode}
        onSelectionModelChange={update}
        selectionModel={selection}
        getRowClassName={(params) => `super-app-theme--${params.getValue(params.id, "isOnline")}`}
      /> */}
    </div>
  );
};

const DeviceListLoader = ({ isLoading, isError, error, devices, selectedStatus, multiSelectionMode }) => {
  const classes = useStyles();

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error: {error.message}</div>
      ) :  (
        <div>
          <div style={{ marginTop: 30 }}>
            <DeviceList multiSelectionMode={multiSelectionMode} onlineOnly={selectedStatus == "online"} devices={devices} />
          </div>
        </div>
      )}
    </div>
  );
};

const toGrid = (items, config) => {
  const { columns } = config;
  let rows = [];

  for (let i = 0; i < items.length; i += columns) {
    rows.push(
      <Grid key={i} container spacing={3}>
        {items.slice(i, i + columns).map((item, j) => (
          <Grid key={`${i}_${j}`} item xs>
            {item}
          </Grid>
        ))}
      </Grid>
    );
  }

  return rows;
};

const BulkDeviceEditor = ({ devices, selection, close }) => {
  const multiple = selection && selection.length > 1;
  const classes = useStyles();

  // const [name, setName] = useState(17);
  // const handleChange = (event) => {
  //   setName(event.target.value);
  // };

  const [oldParams, setOldParams] = useState({});
  const [newParams, setNewParams] = useState({});
  const [deviceSelected, setDeviceSelected] = useState(null);
  const [diff, setDiff] = useState({});

  // const [fields, setState] = React.useState({ });

  const handleChange = (field, event) => {
    console.log("handleChange", field, _.get(event, "target.value"));
    const newVal = _.get(event, "target.value");
    if (newVal != null) {
      setNewParams({ ...newParams, [field]: newVal });
    }
  };

  useEffect(() => {
    const d = devices.find((d) => d.id === selection[0]);
    setDeviceSelected({ ...d });
  }, [selection]);

  useEffect(() => {
    // console.log("newParams", JSON.stringify(newParams, null, 4));
    setDiff(difference(oldParams, newParams));
    // console.log("diff", JSON.stringify(difference(oldParams, newParams), null, 4));
  }, [newParams]);

  useEffect(() => {
    let obj = {};
    for (const { field } of systemParameters) {
      let value = _.get(deviceSelected, field, null);
      obj[field] = value == null || typeof value == "number" ? value : value.value;
    }
    // console.log("obj is ", obj);
    setNewParams(obj);
    setOldParams(obj); // those wont change when inputs change
  }, [deviceSelected]); // TODO: pensar bem quando seria esta atualizacao

  if (multiple) {
    return (
      <div style={{ margin: 50 }}>
        <h1>--</h1>
      </div>
    );
  }

  if (!selection || selection.length == 0) {
    return <div style={{ width: Constants.editorWidth }}>no selection selected</div>;
  }

  // console.log("selection is here", devices, selection, deviceSelected, devices[0]);

  let parameters = [];
  for (const { field, renderSide: Param, headerName, editable, format, valueOptions, unit, readOnly, output } of systemParameters) {
    // let value = multiple ? "--" : _.get(deviceSelected, field, "Not avail"); //devices[0]; // devices[deviceSelected]; //devices.find((device) => device.id === selection[0]); //_.get(selection[0], field, "Not avail.");
    // const Param = renderSide;
    parameters.push(
      <Param field={field} headerName={headerName} value={newParams[field]} onChange={handleChange} unit={unit} options={valueOptions} readOnly={readOnly} />
    );
  }

  return (
    <div style={{ margin: 20 }}>
      <h1>{selection.length === 1 ? `Device ${selection[0]}` : `${selection.length} devices selected`}</h1>
      {parameters && parameters.length > 0 && (
        <form className={classes.form} noValidate autoComplete="off">
          {toGrid(parameters, { columns: 2 })}
        </form>
      )}
      {/* <pre>{JSON.stringify(devices).slice(0, 100)}</pre>
      <pre>{JSON.stringify(selection).slice(0, 100)}</pre> */}
      <Button
        size="large"
        fullWidth
        disabled={_.isEmpty(diff)}
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          const serial = deviceSelected.id;
          let paramsWrite = {};
          for (const key of _.keys(diff)) {
            if (key === "Setpoint") {
              paramsWrite[key] = celsiusToSetpoint(parseInt(diff[key])); // TODO: deixar... generico... com tabela..
            } else {
              paramsWrite[key] = parseInt(diff[key]); // TODO: other conversion? use table?
            }
          }
          api.remoteWrite("remotewrite", serial, paramsWrite);
          console.log("remoteWrite", serial, paramsWrite);
        }}
      >
        Save
      </Button>
      <Button size="large" fullWidth variant="outlined" color="primary" className={classes.button} onClick={close}>
        Cancel
      </Button>
    </div>
  );
};

const DISABLE_APP_BAR_FILTER = true;



const sample_row = {
  // Esguicho_de_Agua: { value: 0, icon: "Level_0of1", label: "Desl." },
  // Farol_Dianteiro: { value: 0, icon: "Level_0of2", label: "Desl." },
  // Farol_Traseiro: { value: 0, icon: "Level_0of2", label: "Desl." },
  // Limpador_Dianteiro: { value: 0, icon: "Level_0of1", label: "Desl." },
  // Limpador_Traseiro: { value: 0, icon: "Level_0of1", label: "Desl." },
  Setpoint01: 33,
  Setpoint02: 15,
  ValorMaximoDoSetpoint: 30,
  ValorMinimoDoSetpoint: 30,
  OffSetSensorCamara1: 30,
  OffsetSensorDeSuccao: 30,
  OffSetSensorCamara2: 30,
  OffSetDoSensorDeSerpentina: 30,
  HabilitaSensorDeSerpentina: { value: 1, icon: "Level_0of1", label: "Lig." },
  HabilitaSensorDePorta: { value: 0, icon: "Level_0of1", label: "Desl." },
  HabilitaSensorTemperaturaCamara2: { value: 0, icon: "Level_0of1", label: "Desl." },
  HabilitaSensorDeSerpentina: { value: 1, icon: "Level_0of1", label: "Lig." },
  HabilitaLogSomenteApolloEFlex: { value: 1, icon: "Level_0of1", label: "Lig." },
  // Velocidade_da_Ventilacao: { value: 0, icon: "Level_0of3", label: "Desl." },

  // metadata
  isOnline: "Online",
  lastChange: "about 2 months",
  lastRead: "about 2 months",
  protocol: "",
  readPending: "Sync",
};

const MOCK_FK_ROWS = _.range(0, 10).map((n) => ({
  ...sample_row,
  id: `Disp. ${n + 1}`,
  Setpoint01: _.random(15, 60),
  Setpoint02: _.random(15, 60),
  ValorMaximoDoSetpoint: _.random(15, 60),
  ValorMinimoDoSetpoint: _.random(15, 60),
  OffSetSensorCamara1: _.random(15, 60),
  OffsetSensorDeSuccao: _.random(15, 60),
  OffSetSensorCamara2: _.random(15, 60),
  OffSetDoSensorDeSerpentina: _.random(15, 60),
  isOnline: _.random(100) > 70 ? "Online" : "Offline",
}));

const Content = () => {
  const [page, setPage] = React.useState(0);

  const { selection, update } = useSelectionContext();
  const [rows, setRows] = React.useState(MOCK_FK_ROWS);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [multiSelectionMode, setMultiSelectionMode] = React.useState(false);
  const { auth } = useAuthContext();

  const grouped = _.groupBy(rows, "protocol");
  const filtered = DISABLE_APP_BAR_FILTER ? rows : _.filter(rows, ["protocol", selectedProject]);

  // console.log("filtered", filtered);

  const classes = useStyles();

  const { isLoading, isError, error, resolvedData } = usePaginatedQuery(["devices", page], api.devicesList, {
    refetchInterval: 5000,
    refetchIntervalInBackground: true, // tab in background
  });


  
  useEffect(() => {
    if (!isLoading && !isError) {
      // console.log("ok now", convert(resolvedData.results));
      // const { original, formatted } = convert(resolvedData.results);
      console.log("data from server", resolvedData.results);
      const rows = convert(resolvedData.results);
      // setRows(rows);
      setRows(MOCK_FK_ROWS); // use rows from server
      if (selectedProject == null) {
        setSelectedProject(_.keys(grouped)[0]); // todo: not that good ?
      }
    } else {
      // console.log("isLoading", isLoading, isError);
      // setRows([]);
    }
  }, [isLoading, isError, resolvedData, selectedProject]);

  // useEffect(() => {
  //   console.log("rows", rows);
  // }, [rows]);

  const open = selection && selection.length > 0;

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {/* <DeviceAppBar
          enableFilterDropdown={!DISABLE_APP_BAR_FILTER}
          title="Devices"
          projects={_.keys(grouped)}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          multiSelectionMode={multiSelectionMode}
          setMultiSelectionMode={setMultiSelectionMode}
        /> */}
      
        <div className={classes.deviceListLoader}>
          <DeviceListLoader
            selectedStatus={selectedStatus}
            selectedProject={selectedProject}
            isLoading={false&&isLoading}
            isError={false&&isError}
            error={error}
            devices={filtered}
            multiSelectionMode={multiSelectionMode}
          />
        </div>
      </main>
      <div>
        <Drawer
          className={classes.multiControl}
          variant="persistent"
          anchor={"right"}
          open={open}
          onClose={() => update([])}
          classes={{
            paper: classes.editorDrawerPaper,
          }}
        >
          <BulkDeviceEditor devices={rows} selection={selection} close={() => update([])} />
        </Drawer>
      </div>
    </>
  );
};

export default function Device() {
  // const { devices, selected } = React.useContext(DevicesContext);
  const classes = useStyles();
  let { serial } = useParams();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuLateral />
      <SelectionProvider>
        <Content />
      </SelectionProvider>
    </div>
  );
}

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .super-app-theme--Online": {
      backgroundColor: theme.status.online,
      "&:hover": {
        backgroundColor: lighten(theme.status.online, 0.1),
      },
    },
  },
  appBar: {
    width: `calc(100% - ${Constants.drawerWidth}px)`,
    marginLeft: Constants.drawerWidth,
  },
  drawer: {
    width: Constants.drawerWidth,
    flexShrink: 0,
  },
  multiControl: {
    width: Constants.editorWidth,
    flexShrink: 0,

    // maxWidth: 400,
    // "& .MuiPaper-root": {
    //   zIndex: 10000000, // breaks selects...
    // },
  },
  deviceListLoader: {
    margin: theme.spacing(2),
  },
  drawerPaper: {
    width: Constants.editorWidth,
    backgroundColor: "#172026",
    color: "white",
  },
  editorDrawerPaper: {
    width: Constants.editorWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -Constants.editorWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey[400],
  },
  menuHeader: {
    color: "#bbb",
    // fontSize: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 240,
  },
  selectAdornment: {
    marginRight: 6, //theme.spacing(1), its 8
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
