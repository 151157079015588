import { Grid } from "@material-ui/core";

import React, {useEffect, useState } from "react";

import { useDashBoardGraphs } from "./styles";
import Chart from "react-apexcharts";
import { useTimeredGetRequest } from "../../../hooks/useTimeredRequest";
import { getTemperatureHistoryById } from "../../../api-globusway/api";


const Graph = () => {
  const apiResponse = useTimeredGetRequest(getTemperatureHistoryById, "b7fc4004-335d-4bc8-a98b-510367d45af9", 60);

  const [series, setSeries] = useState([]);
  const [timeStamp, setTimeStamp] = useState([]);
  // console.log("GRAPHS:", apiResponse);

  useEffect(() => {
    // console.log("GRAPHS:", apiResponse.data);
    if (apiResponse.data) {
      const series = [
        {
          name: "Internal Temperature",
          data: apiResponse.data.internalTemperature,
        },
        {
          name: "Setpoint",
          data: apiResponse.data.setpoint,
        },
      ];
      const formatedDate = apiResponse.data.createdAt.map((item) =>
        new Date(item).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          day: "numeric",
          month: "numeric",
          timeZone: 'America/New_York'
        })
      );

      setSeries(series);
      setTimeStamp(formatedDate);
    }
  }, [apiResponse.data]);

  // const series = [
  //   {
  //     name: "Temperatura Interna",
  //     data: [8, 5, 4, 2, 0, -1, -3, -5],
  //   },
  //   {
  //     name: "Setpoint",
  //     data: [4, 3, 2, 6, 1, -3, -6, 12],
  //   },
  // ];

  // const options = {
  //   chart: {
  //     id: "basic-bar",
  //   },
  //   xaxis: {
  //     categories: ["12:00", "12:05", "12:10", "12:15", "12:20", "12:25", "12:30", "12:35", "12:40"],
  //   },
  //   colors: ["#D96B2B", "#5A6973"],
  // };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* {`Tamanho do pai: ${tamanhoComponentePai.width}px x ${tamanhoComponentePai.height}px`} */}
      {timeStamp.length !== 0 && (
        <Chart
          options={{
            chart: {
              id: "basic-bar",
              type: "line",
              toolbar: {
                show: true,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                },
              },
            },
            xaxis: {
              categories: timeStamp,
              tickAmount: 10,
            },

            yaxis: {
              show: true,
              title: {
                text: "Temperature (ºF)",
                style: {
                  fontSize: "15px",
                  color: "#5A6973",
                },
              },
            },

            colors: ["#D96B2B", "#5A6973"],
          }}
          series={series}
          type="line"
          width="100%"
          height="100%"
        />
      )}

      {timeStamp.length === 0 && <p style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '90%',color: '#5A6973'}}>No data yet</p>}
    </div>
  );
};

function DashBoardGraphs() {
  const styles = useDashBoardGraphs();

  //JSON => JavaScript Object Notation

  return (
    <Grid item xs={12} sm={12} md={12} lg={5} style={{ height: "108%" }}>
      <div className={styles.wrapper}>
        <div style={{ width: "100%", minWidth: 300, height: "100%", display: "flex", padding: 10 }}>
          <Graph />
        </div>
      </div>
    </Grid>
  );
}

export default DashBoardGraphs;

/*
          TODO: Aqui dentro deve-se realizar a requisição do histórico de temperatura dos sensores para a API. De maneira provisória vamos realizar apenas a
          aquisição dos dados do dia atual, a fim de evitar bugs na contrução do gráfico.
          <br></br>
          <br></br>
          Posteriormente a requisição terá um range de datas como input e, através dele, será possível adquirir dados de qualquer data armazenada no banco de
          dados.
          <br></br>
          <br></br>
          Esse componente irá renderizar um gráfico de uma bliblioteca chamada "react-chartjs-2", ela é uma biblioteca com um bom suporte da comunidade além de
          ser contruida inteiramente com HTML,CSS, JavaScript, TypeScript e MDX, todas ferramentas básicas que dificilmente ficaram obsoletas.
 */
