import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import env from "../env";

import axios from "axios";
//import Cookies from "js-cookie";

import Copyright from "../components/Copyright";
import background_default from "../assets/images/login_bg.jpg";
import background_fk from "../assets/images/brands/banner_fk.jpg";
import roundLogo from "../assets/images/round_logo.png";
import smallLogo_fk from "../assets/images/brands/brand_frigoking_login.png";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";

import * as api from "../api";
import { useAuthContext } from "../hooks/useAuth";
import { useFormik } from "formik";
import * as yup from "yup";

const background = env.Client == "FK" ? background_fk : background_default;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(" + background + ")",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(3),
    height: 80,
    width: 80,
  },
  banner_fk: {
    margin: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const StyledButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #ff6d00 30%, #ff9100 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px #eee",
  },
  label: {},
})(Button);

const Message = ({ text, error }) => (
  <Box mt={4}>
    <Typography align="center" color={error ? "error" : "primary"} component="h1" variant="h6">
      {text || "--"}
    </Typography>
  </Box>
);

export default function Login() {
  const classes = useStyles();
  const [status, setStatus] = React.useState("initial");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string("Enter an email address").required("Email is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      api
        .resetPasswordAsk(values.email)
        .then((result) => setStatus("success"))
        .catch((err) => {
          if (typeof err.response.status === "string") {
            formik.setErrors({ email: err.response.data });
          } else if (typeof err.response.data === "object" && "user" in err.response.data) {
            formik.setErrors({ email: "Este usuário não existe no sistema. Verifique o email e tente novamente." });
          } else {
            formik.setErrors({ email: "Não foi possível pedir uma nova senha. Verifique o email ou entre em contato conosco." });
          }
        });
      try {
      } catch (error) {
        console.log("errors", error);
        // setErrors(errorToFormik(error.response.data));
      }
    },
  });

  let content;

  if (status == "success") {
    content = <Message text="Verifique seu email para resetar sua senha!" />;
  } else {
    content = (
      <>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <StyledButton type="submit" fullWidth variant="contained" onClick={formik.handleSubmit} className={classes.submit}>
          Pedir nova senha
        </StyledButton>
      </>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {env.Client == "Default" && <Avatar className={classes.avatar} src={roundLogo} />}
          {env.Client == "FK" && <img className={classes.banner_fk} src={smallLogo_fk} />}
          <Typography component="h1" variant="h5">
            Esqueci minha senha
          </Typography>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            {content}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
