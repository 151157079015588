import { useEffect, useRef, useState } from "react";
import { getAllDataById } from "../api-globusway/api";
import { isObject } from "lodash";




export const useTimeredGetRequest = (request, id, periodInSeconds) => {
  const [data, setData] = useState({});
  const dataRef = useRef({});

  const deepCompareObjects = (prevObj, newObj) => {
    const keysA = Object.keys(prevObj);
    const keysB = Object.keys(newObj);

    if (keysA.length !== keysB.length) {
      return false;
    }
    for (let key of keysA) {
      if (prevObj[key] !== newObj[key]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await request(id);
        // console.log("request received");
        const isObjectEqual = deepCompareObjects(dataRef.current, response);
        if (!isObjectEqual) {
          // console.log("-------------------------------------------------");
          // console.log("data", data);
          // console.log("response", response);
          // console.log("isObjectEqual", isObjectEqual);
          // console.log("data render for", request);
          // console.log("-------------------------------------------------");
          dataRef.current = response;
          setData(response);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    fetchData();

    // Configura o intervalo para chamar fetchData a cada 5 segundos
    const intervalId = setInterval(fetchData, periodInSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [id]);

  return data;
};
