import _ from "lodash";
import React from "react";
import { usePaginatedQuery, useQuery } from "react-query";
import * as api from "../api";

import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";
import { SimpleAppbar } from "../components/DevicesAppBar";
import { Drawer } from "../components/Drawer";

import MUIDataTable from "mui-datatables";

import { DialogBulkMoveDevice, DialogDeviceChange } from "./components/DeviceForms";
import CustomToolbarSelect from "./components/CustomToolbarSelect";

const tableColumns = [
  {
    name: "serial",
    label: "Serial",
  },
  {
    name: "client_detail.name",
    label: "Cliente",
    options: {
      customBodyRender: (value) => value || "--",
    },
  },
  //   {
  //     name: "last_name",
  //     label: "Sobrenome",
  //   },
  //   {
  //     name: "client_detail.name",
  //     label: "Cliente",
  //     options: {
  //       customBodyRender: (value) => value || "--",
  //     },
  //   },
  //   {
  //     name: "fk_level",
  //     label: "Acesso",
  //     options: {
  //       customBodyRender: (value) => value || "--",
  //     },
  //   },
];

const TITLE = "Dispositivos";

export default () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [moveList, setMoveList] = React.useState([]);
  const [bulkDialog, setBulkDialog] = React.useState(null);
  const [rowsSelected, setRowsSelected] = React.useState([]);

  const slug = null;

  const { refetch, isLoading, isError, error, data } = usePaginatedQuery(["devices", page], api.devicesList);

  let content;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Error: {error.message}</div>;
  } else if (data && data.results) {
    content = (
      <MUIDataTable
        title={TITLE}
        data={data.results}
        columns={tableColumns}
        options={{
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
              selectedRows={selectedRows}
              displayData={displayData}
              setSelectedRows={setSelectedRows}
              transfer={{
                label: "Mover dispositivos",
                onClick: (selection) => {
                  const indices = selection?.data?.map((row) => row.dataIndex);
                  const devices = [...data?.results?.filter((_, index) => indices?.includes(index))];
                  setMoveList(devices);
                  setBulkDialog("move");
                },
              }}
            />
          ),
          enableNestedDataAccess: ".", // lodash's get style
          selectableRows: "multiple",
          rowsPerPage: 100,
          print: false,
          rowsSelected: rowsSelected,
          onRowClick: (_, meta) => setValue(data.results[meta.dataIndex]),
        }}
      />
    );
  } else {
    <div>
      <div>Invalid data</div>
      <pre>{JSON.stringify(data.results, null, 4)}</pre>
    </div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer />
      <main className={classes.content}>
        <SimpleAppbar title={TITLE} />
        <DialogDeviceChange
          initial={value}
          isOpen={value != null && value.serial != null}
          onClose={() => {
            refetch();
            setValue(null);
          }}
        />
        <DialogBulkMoveDevice
          isOpen={bulkDialog == "move"}
          deviceList={moveList}
          onClose={() => {
            refetch();
            setBulkDialog(null);
          }}
        />
        <div className={classes.list}>{content}</div>
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    margin: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
}));
