import axios from "axios";
import _ from "lodash";
//import Cookies from "js-cookie";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3535/" // local https fails because certificate is ... temporary
  :  "http://localhost:3535/" // : "https://api.globusway.com/";

const client = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "X-Tenant": "globus",
  },
});

function getHeaders() {
  // const token = Cookies.get("token");
  const authJSON = localStorage.getItem("auth");
  let token;
  // let impersonate;

  if (authJSON) {
    const auth = JSON.parse(authJSON);
    token = auth.token;
    // impersonate = auth.impersonate;
  }

  if (!token) {
    console.log("no Token present!?");
    return;
  }
  let output = {
    Authorization: `Token ${token}`,
  };
  // if (impersonate) {
  //   output["X-Impersonate"] = impersonate;
  // }
  return output;
}

function asAPI(resource) {
  const headers = getHeaders();
  return {
    getOne: async ({ id }) => {
      const { data } = await client.get(`${resource}/${id}`, { headers });
      return data;
    },
    getAll: async () => {
      const { data } = await client.get(`${resource}`, { headers });
      return data;
    },
    getAllAlternative: async () => {
      const { data } = await client.get(`${resource}`, { headers });
      if (data.results) {
        return {
          ...data,
          results: data.results.map((el) => ({ ...el, username: el["email"] || el["username"] })),
        };
      }
      return data;
    },
    // update: (toUpdate) => client.put(`${resource}/`, toUpdate, { headers }),
    update: (toUpdate) => client.patch(`${resource}/${toUpdate.id}/`, toUpdate, { headers }), // patch is partial update
    create: (toCreate) => client.post(`${resource}/`, toCreate, { headers }),
    delete: ({ id }) => client.delete(`${resource}/${id}`, { headers }),
  };
}

export const devicesList = async (key, page) => {
  const { data } = await client.get(`devices?fk=1&page=${page + 1}`, {
    headers: getHeaders(),
  });
  return data;
};

export const deviceDetail = async (key, serial) => {
  const { data } = await client.get(`devices/${serial}/`, {
    headers: getHeaders(),
  });
  return data;
};

export const remoteWrite = async (key, serial, params) => client.post(`devices/${serial}/remote/`, { params: params });

////////////////////////////////////////////////
// api
////////////////////////////////////////////////

const traverse = function (visit, clients, path) {
  for (let c of clients) {
    visit(c, path);
    traverse(visit, c.clients, [...path, c.slug]);
  }
};

export const clientTree = async () => {
  const { data } = await client.get(`/clients/tree`, {
    headers: getHeaders(),
  });

  // Build a list of client-paths from the data
  let meta = {};
  meta["root"] = [data, []]; // root , not slug
  meta[data.slug] = [data, []]; // root , not slug
  traverse((client, path) => (meta[client.slug] = [client, path]), data.clients, [data.slug]);

  // console.log(JSON.stringify(meta, null, 4));

  // Build an array of client with parents
  let metaList = [];
  for (const row of Object.keys(meta)) {
    if (row != "root") {
      const path = meta[row][1];
      metaList.push({
        id: meta[row][0].id,
        name: meta[row][0].name,
        info: meta[row][0].info,
        slug: row,
        parent: path && path[path.length - 1],
        path: path,
        pathNames: path.map((slug) => meta[slug][0].name),
      });
    }
  }

  // traverse((client, path) => (meta[client.slug] = [client, path]), data.clients, [data.slug]);

  return { ...data, meta, metaList };
};
export const login = (data) => client.post("login/", data);

// With auth headers from cookie
export const users = () => asAPI("users");
export const clients = () => asAPI("clients");
export const devices = () => asAPI("devices");

export const moveClient = async (clientSlug, newParentSlug) => {
  await client.post(`clients/move/`, { client: clientSlug, parent: newParentSlug }, { headers: getHeaders() });
};

export const clientsList = async (key, page, impersonate) => {
  const { data } = await client.get(`clients?impersonate=${impersonate}&page=${page + 1}`, {
    headers: getHeaders(),
  });
  return data;
};

export const moveDevice = async (serial, newClient) => {
  await client.post(`devices/${serial}/move/`, { client: newClient }, { headers: getHeaders() });
};

export const resetPasswordCheckToken = async (token) => {
  await client.get(`reset-password/token-validation/`, { params: { token } });
};

export const resetPassword = async (token, password) => {
  await client.post(`reset-password/submit/?token=${token}`, { password });
};

export const resetPasswordAsk = async (email) => {
  await client.post(`reset-password/`, { email });
};

// export const moveDevice = async (serial, newClient) => {
//   await client.post(`devices/${serial}/move/`, { client: newClient }, { headers: getHeaders() });
// };

/* Data examples: meta
{
    "root": [
        {
            "id": 2,
            "slug": "fk",
            "name": "FK",
            "info": {
                "devices": 0,
                "users": 3,
                "children": 2,
                "descendants": 3
            },
            "clients": [
                {
                    "id": 5,
                    "slug": "fk-north",
                    "name": "FK North",
                    "info": {
                        "devices": 1,
                        "users": 1,
                        "children": 1,
                        "descendants": 1
                    },
                    "clients": [
                        {
                            "id": 15,
                            "slug": "fnk12",
                            "name": "FNK12",
                            "info": {
                                "devices": 1,
                                "users": 2,
                                "children": 0,
                                "descendants": 0
                            },
                            "clients": []
                        }
                    ]
                }
            ]
        },
        []
    ],
    "fk": [
        {
            "id": 2,
            "slug": "fk",
            "name": "FK",
            "info": {
                "devices": 0,
                "users": 3,
                "children": 2,
                "descendants": 3
            },
            "clients": [
                {
                    "id": 5,
                    "slug": "fk-north",
                    "name": "FK North",
                    "info": {
                        "devices": 1,
                        "users": 1,
                        "children": 1,
                        "descendants": 1
                    },
                    "clients": [
                        {
                            "id": 15,
                            "slug": "fnk12",
                            "name": "FNK12",
                            "info": {
                                "devices": 1,
                                "users": 2,
                                "children": 0,
                                "descendants": 0
                            },
                            "clients": []
                        }
                    ]
                }
            ]
        },
        []
    ],
    "fk-north": [
        {
            "id": 5,
            "slug": "fk-north",
            "name": "FK North",
            "info": {
                "devices": 1,
                "users": 1,
                "children": 1,
                "descendants": 1
            },
            "clients": [
                {
                    "id": 15,
                    "slug": "fnk12",
                    "name": "FNK12",
                    "info": {
                        "devices": 1,
                        "users": 2,
                        "children": 0,
                        "descendants": 0
                    },
                    "clients": []
                }
            ]
        },
        [
            "fk"
        ]
    ],
    "fnk12": [
        {
            "id": 15,
            "slug": "fnk12",
            "name": "FNK12",
            "info": {
                "devices": 1,
                "users": 2,
                "children": 0,
                "descendants": 0
            },
            "clients": []
        },
        [
            "fk",
            "fk-north"
        ]
    ]
}*/
