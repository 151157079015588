import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import MoveIcon from "@material-ui/icons/ImportExport";
import React from "react";

const useStyles = makeStyles({
  iconButton: {},
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
});

export default ({ selectedRows, transfer, remove }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      {transfer && (
        <Tooltip title={transfer.label}>
          <IconButton className={classes.iconButton} onClick={() => transfer.onClick(selectedRows)}>
            <MoveIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
      {remove && (
        <Tooltip title={remove.label}>
          <IconButton className={classes.iconButton} onClick={() => remove.onClick(selectedRows)}>
            <Delete className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
