import { Box, Button, CardActionArea } from "@material-ui/core";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";

const makeLinkOpen = (username) => "/user/" + username;

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function UserCard({ username, client, email, first_name, last_name, user_type, onClick }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          {/* <Typography color="textSecondary">{user_type}</Typography> */}
          <Typography variant="h5" component="h2">
            {first_name} {last_name}
          </Typography>
          {email && (
            <Typography style={{ fontSize: 13 }} color="textSecondary">
              Email: {email}
            </Typography>
          )}
          {username && email != username && (
            <Typography style={{ fontSize: 13 }} color="textSecondary">
              Username: {username}
            </Typography>
          )}
          {client && (
            <Typography style={{ fontSize: 13 }} color="textSecondary">
              Client: {client}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
