import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Box, FormHelperText } from "@material-ui/core";

export const EnumValue = ({ id, label, value, options, onChange, name, error, helperText, disabled }) => {
  return (
    <Box mt={2} mb={1}>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        error={error}
        fullWidth
        id={id}
        value={value}
        onChange={(event) => {
          console.log("onChange", event.target.value);
          onChange(event.target.value);
        }}
      >
        {options.map((entry, key) => (
          <MenuItem key={key} value={typeof entry == "object" ? entry.value : entry}>
            {typeof entry == "object" ? entry.display : entry}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </Box>
  );
};
