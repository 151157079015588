import { Grid } from "@material-ui/core";
import React from "react";
import { useThermalControlCardStyle } from "./style";
import { useTimeredGetRequest } from "../../../hooks/useTimeredRequest";
import { getThermalControlCardInfoById } from "../../../api-globusway/api";
import { round, isEmpty } from "lodash";
import { BarGraph } from "./BarGraph";

function ThermalControlCard({ serial }) {
  const style = useThermalControlCardStyle();
  const apiResponse = useTimeredGetRequest(getThermalControlCardInfoById, "b7fc4004-335d-4bc8-a98b-510367d45af9", 2);

  const operationModeStatus = {
    [0]: "Off",
    [1]: "Auto",
    [2]: "Cooling",
    [4]: "Heating",
    [8]: "Venting",
  };

  const _setPointValue = isNaN(apiResponse.setpoint / 10) ? "..." : round(apiResponse.setpoint / 10);
  const _temperatureUnit = _setPointValue === "..." ? "" : "º" + "F";
  const _blowerStatus = Number.parseInt(apiResponse.blowerSpeed);
  const _operationMode = apiResponse.operationMode;

  const _activeFunction = operationModeStatus[_operationMode];
  const _internalTemperature = Number.parseInt(apiResponse.returnTemperature / 10);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} style={{ height: "108%" }}>
      <div className={style.wrapper}>
        <div className={style.container}>
          {!isEmpty(apiResponse) && (
            <>
              <div className={style.cardTitle}>Thermal Control</div>
              <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
                <div className={style.thermalInfoContainer}>
                  <div className={style.thermalInfoTitleText}>
                    Setpoint: <br />
                    <div className={style.thermalInfoDataText}>{`${_setPointValue}${_temperatureUnit}`}</div>
                  </div>

                  <div className={style.thermalInfoTitleText}>
                    Ventilation Level: <br />
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                      <BarGraph data={_blowerStatus} mode = {_operationMode } maxValue={6} />
                    </div>
                  </div>

                  <div className={style.thermalInfoTitleText}>
                    Operation Function: <br />
                    <div className={style.thermalInfoDataText}>{`${_activeFunction}`}</div>
                  </div>
                </div>

                <div className={style.internalTemperatureContainer}>
                  <div className={style.internalTemperatureText}>
                    {`${_internalTemperature}${_temperatureUnit}`}
                    <br />
                    <div className={style.internalTemperatureSubTitle}>Internal</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Grid>
  );
}

export default ThermalControlCard;
