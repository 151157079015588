import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { deepOrange } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import * as api from "../api";
import ClientHierarchyBreadcrumbs from "../components/ClientBreadcrumbs";
import { Constants, Drawer as MenuLateral } from "../components/Drawer";
import UserCard from "../components/UserCard";
import { useAuthContext } from "../hooks/useAuth";
import useClient from "../hooks/useClient";
import { SelectionProvider, useSelectionContext } from "../hooks/useSelectionContext";
import { UserChanger } from "../pages-fk/components/UserChanger";

const Content = () => {
  const [page, setPage] = React.useState(0);

  const { selection, update } = useSelectionContext();
  const [rows, setRows] = React.useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState("all");
  const [changeUser, setChangeUser] = useState(null);

  const [multiSelectionMode, setMultiSelectionMode] = React.useState(false);
  const { auth } = useAuthContext();
  const classes = useStyles();

  const { client } = useClient();

  let { client: slug } = useParams();
  slug = slug || "root";

  const makeAddUserLink = () => {
    return slug == "root" ? `/user/new` : `/user/${slug}/new`;
  };

  const { isLoading, isError, error, data: users } = useQuery(["users", slug || "root"], api.users().getAll);
  console.log("users", users, isLoading, error);
  const open = false;

  return (
    <>
      <main className={classes.content}>
        <UserChanger
          user={changeUser}
          // description={changeDescription}
          // value={changeValue}
          // pendingValue={pendingValue}
          isOpen={!!changeUser}
          onClose={() => setChangeUser(null)}
          onSave={(value) => {
            // api.remoteCommand(null, serial, changeParam, normalizeValue(value)).then((result) => {
            //   console.log("onSave", value);
            //   setChangeParam(null);
            //   pendingRemote.refetch();
            // });
          }}
        />

        <Box m={3}>
          {isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error.message}</div>
          ) : (
            // `resolvedData` will either resolve to the latest page's data
            // or if fetching a new page, the last successful page's data
            <div>
              <Box mt={1} mb={1}>
                <ClientHierarchyBreadcrumbs />
              </Box>

              <Box display="flex" alignItems="space-between" mb={2}>
                <Box flexGrow={1}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    Users for <b>{client && client.name}</b>
                  </Typography>
                  {/* <div>{JSON.stringify(myPath)}</div> */}
                </Box>
                <div>
                  <Button startIcon={<AddIcon />} variant="outlined" color="secondary" component={RouterLink} to={makeAddUserLink()}>
                    Add user
                  </Button>
                </div>
              </Box>

              <Grid container spacing={1}>
                {users &&
                  users.results.map((user) => (
                    <Grid item xs={12} md={6} lg={3}>
                      <UserCard
                        {...user}
                        onClick={() => {
                          setChangeUser(user);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>

              <Grid container spacing={3}>
                {/* {users.map((client) => (
                  <Grid item xs={12} md={6} lg={3}>
                    <ClientCard {...client} />
                  </Grid>
                ))} */}
              </Grid>
            </div>
          )}
        </Box>
      </main>
      {/* <div>
        <Drawer
          className={classes.multiControl}
          variant="persistent"
          anchor={"right"}
          open={open}
          onClose={() => update([])}
          classes={{
            paper: classes.editorDrawerPaper,
          }}
        >
          <BulkDeviceEditor devices={rows} selection={selection} close={() => update([])} />
        </Drawer> 
        </div>*/}
    </>
  );
};

export default function Device() {
  // const { devices, selected } = React.useContext(DevicesContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuLateral />
      <SelectionProvider>
        <Content />
      </SelectionProvider>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    display: "flex",
    "& .super-app-theme--Online": {
      backgroundColor: theme.status.online,
      "&:hover": {
        backgroundColor: lighten(theme.status.online, 0.1),
      },
    },
  },
  appBar: {
    width: `calc(100% - ${Constants.drawerWidth}px)`,
    marginLeft: Constants.drawerWidth,
  },
  drawer: {
    width: Constants.drawerWidth,
    flexShrink: 0,
  },
  multiControl: {
    width: Constants.editorWidth,
    flexShrink: 0,

    // maxWidth: 400,
    // "& .MuiPaper-root": {
    //   zIndex: 10000000, // breaks selects...
    // },
  },
  deviceListLoader: {
    margin: theme.spacing(2),
  },
  drawerPaper: {
    width: Constants.editorWidth,
    backgroundColor: "#172026",
    color: "white",
  },
  editorDrawerPaper: {
    width: Constants.editorWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginRight: -Constants.editorWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey[400],
  },
  menuHeader: {
    color: "#bbb",
    // fontSize: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 240,
  },
  selectAdornment: {
    marginRight: 6, //theme.spacing(1), its 8
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
