import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";
import * as api from "../api";

import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";
import { SimpleAppbar } from "../components/DevicesAppBar";
import { Drawer } from "../components/Drawer";

import MUIDataTable from "mui-datatables";

import { DialogBulkMove, DialogBulkRemove, DialogUserChange, DialogUserCreate } from "./components/UserForms";
import MiniTable from "./components/MiniTable";
import { TableCell, TableRow } from "@material-ui/core";
import CustomToolbarSelect from "./components/CustomToolbarSelect";

const extraDataRows = [
  { key: "name", label: "Nome" },
  { key: "cpf", label: "CPF" },
  { key: "phone", label: "Telefone" },
];

const renderJsonData = (input) => {
  if (input == "") return "--";
  try {
    const data = JSON.parse(input);
    if (typeof data != "object" || _.isEmpty(data)) {
      return "--";
    }
    let items = [];
    for (const { key, label } of extraDataRows) {
      items.push({ label, value: data[key] });
    }
    return <MiniTable rows={items} />;
  } catch (error) {
    return "(err)";
  }
};

const tableColumns = [
  {
    name: "username",
    label: "Usuário",
    options: {
      customBodyRender: (value) => value || "--",
    },
  },
  {
    name: "first_name",
    label: "Nome",
  },
  {
    name: "last_name",
    label: "Sobrenome",
  },
  {
    name: "client_detail.name",
    label: "Cliente",
    options: {
      customBodyRender: (value) => value || "--",
    },
  },
  {
    name: "fk_level",
    label: "Acesso",
    options: {
      customBodyRender: (value) => value || "--",
    },
  },
  {
    name: "extra_data",
    label: "Cadastro",
    options: {
      customBodyRender: renderJsonData,
    },
  },
];

const TITLE = "Usuários";

export default () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [moveList, setMoveList] = React.useState([]);
  const [removeList, setRemoveList] = React.useState([]);
  const [bulkDialog, setBulkDialog] = React.useState(null);
  const [rowsSelected, setRowsSelected] = React.useState([]);

  const slug = null;
  const { isLoading, isError, error, data, refetch } = useQuery(["users", slug || "root"], api.users().getAllAlternative);

  let content;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Error: {error.message}</div>;
  } else if (data && data.results) {
    content = (
      <MUIDataTable
        title={TITLE}
        data={data.results}
        columns={tableColumns}
        options={{
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
              selectedRows={selectedRows}
              displayData={displayData}
              setSelectedRows={setSelectedRows}
              transfer={{
                label: "Mover usuários",
                onClick: (selection) => {
                  const indices = selection?.data?.map((row) => row.dataIndex);
                  const users = [...data?.results?.filter((_, index) => indices?.includes(index))];
                  // console.log({ users });
                  setMoveList(users);
                  setBulkDialog("move");
                },
              }}
              remove={{
                label: "Remover usuários",
                onClick: (selection) => {
                  const indices = selection?.data?.map((row) => row.dataIndex);
                  const users = [...data?.results?.filter((_, index) => indices?.includes(index))];
                  setRemoveList(users);
                  setBulkDialog("remove");
                },
              }}
            />
          ),
          enableNestedDataAccess: ".", // lodash's get style
          selectableRows: "multiple",
          rowsPerPage: 100,
          print: false,
          rowsSelected: rowsSelected,
          onRowClick: (_, meta) => setValue(data.results[meta.dataIndex]),
        }}
      />
    );
  } else {
    <div>
      <div>Invalid data</div>
      <pre>{JSON.stringify(data.results, null, 4)}</pre>
    </div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer />
      <main className={classes.content}>
        <SimpleAppbar title={TITLE} buttons={[{ label: "Novo usuário", onClick: () => setValue({}) }]} />
        <DialogUserChange
          user={value}
          isOpen={value != null && value.id != null}
          onClose={() => {
            refetch();
            setValue(null);
          }}
        />
        <DialogUserCreate
          user={value}
          isOpen={value != null && value.id == null}
          onClose={() => {
            refetch();
            setValue(null);
          }}
        />
        <DialogBulkMove
          isOpen={bulkDialog == "move"}
          userList={moveList}
          onClose={() => {
            refetch();
            setBulkDialog(null);
          }}
        />
        <DialogBulkRemove
          isOpen={bulkDialog == "remove"}
          userList={removeList}
          onClose={() => {
            refetch();
            setBulkDialog(null);
          }}
        />
        <div className={classes.list}>{content}</div>
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    margin: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
}));
