import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { useEffect } from "react";
import { Box, InputAdornment, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const EnumValue = ({ value, options, onChange }) => {
  return (
    <Select style={{ fontSize: "1.5rem" }} value={value} onChange={(event) => onChange(event.target.value)}>
      {options.map((val, i) => (
        <MenuItem key={i} value={i}>
          {val}
        </MenuItem>
      ))}
    </Select>
  );
};

const MinMaxValue = ({ value, min, max, unit, onChange, onKeyUp }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  let InputProps = {
    style: { fontSize: "2rem" },
  };
  if (unit) {
    InputProps.endAdornment = (
      <InputAdornment disableTypography style={{ marginLeft: 5, fontSize: "2rem", color: "#666" }} position="end">
        {unit}
      </InputAdornment>
    );
  }

  return (
    <div>
      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={InputProps}
        value={value}
        onChange={handleChange}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            onKeyUp(ev);
          }
        }}
      />
      <Box mt={1}>
        <span style={{ display: "inline-block" }}>
          Min: <b>{min}</b> {unit}
        </span>
        <span style={{ display: "inline-block", marginLeft: 10 }}>
          Máx: <b>{max}</b> {unit}
        </span>
      </Box>
    </div>
  );
};

const getValue = (v, pending) => {
  if (v == null) return "invalid";
  if (pending) return pending;
  if (typeof v != "object") return v;
  if (v.tipo == "minmax") return v.current;
  if (v.tipo == "enum") return v.selected;
  throw new Error("Unknown type", v.tipo);
};

const validateMinMax = (value, min, max) => {
  if (value == null) return false;
  if (typeof value == "string" && value.trim().length == 0) return false;
  const numberValue = parseFloat(value);
  if (typeof numberValue != "number") return false;
  if (numberValue < min || numberValue > max) return false;
  return true;
};
const validateEnum = (value, options) => {
  if (value == null) return false;
  if (Number.isNaN(value)) return false;
  if (!options) return false;
  if (value < 0 || value >= options.length) return false;
  return true;
};

export const ParamChanger = ({ param, description, value, open, handleClose, onSave, pendingValue }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);
  const [valid, setValid] = useState(false);

  const onChange = (event) => {
    console.log("onchange", event.target.value);
  };

  useEffect(() => {
    if (open) {
      setSelected(getValue(value, pendingValue));
    }
  }, [open, pendingValue]);

  useEffect(() => {
    if (value && value.tipo) {
      if (value.tipo == "minmax") setValid(validateMinMax(selected, value.min, value.max));
      else if (value.tipo == "enum") setValid(validateEnum(selected, value.options));
      else setValid(selected != null);
    }
  }, [value, selected]);

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSave(selected);
    }
  };

  return (
    <Dialog maxWidth={"sm"} open={open} onClose={handleClose} onKeyUp={onKeyUp}>
      <DialogTitle>{description}</DialogTitle>
      <DialogContent>
        <DialogContentText>Selecione um novo valor para o parâmetro</DialogContentText>
        {/* <pre>{JSON.stringify({ selected, options: value ? value.options : "--" })}</pre> */}
        <form className={classes.form} noValidate>
          <FormControl className={classes.formControl}>
            {value && value.tipo == "enum" ? <EnumValue value={selected} options={value.options} onChange={setSelected} /> : null}
            {value && value.tipo == "minmax" ? (
              <MinMaxValue onKeyUp={onKeyUp} value={selected} min={value.min} max={value.max} unit={value.unit} onChange={setSelected} />
            ) : null}
          </FormControl>
          {/* <FormControlLabel className={classes.formControlLabel} control={<Switch checked={true} onChange={handleFullWidthChange} />} label="Full width" /> */}
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={!valid} onClick={() => onSave(selected)} variant="contained" color="secondary">
          Salvar
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
