import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import Copyright from "../components/Copyright";
import * as yup from "yup";
import * as api from "../api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2),
  },
}));

const validatePassword = yup.object({
  password: yup.string().min(6, "Precisa ter no mínimo 6 caracteres").required("Digite uma senha válida"),
});

const Message = ({ text, error }) => (
  <Box mt={4}>
    <Typography align="center" color={error ? "error" : "primary"} component="h1" variant="h6">
      {text || "--"}
    </Typography>
  </Box>
);

export default function ResetPassword() {
  const classes = useStyles();
  const [token, setToken] = React.useState("");
  const [status, setStatus] = React.useState("loading");

  React.useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.token) {
      api
        .resetPasswordCheckToken(params.token)
        .then(() => {
          setStatus("ready");
          setToken(params.token);
        })
        .catch(() => setStatus("invalid"));
    } else {
      setStatus("invalid");
      console.log(params);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validatePassword,
    onSubmit: async (values, { setErrors }) => {
      api
        .resetPassword(token, values.password)
        .then((result) => setStatus("success"))
        .catch((err) => formik.setErrors({ password: err.response.data }));
      try {
      } catch (error) {
        console.log("errors", error);
        // setErrors(errorToFormik(error.response.data));
      }
    },
  });

  let content;

  if (status == "success") {
    content = <Message text="Senha alterada com sucesso!" />;
  } else if (status == "loading") {
    content = <Message text="Carregando..." />;
  } else if (status == "invalid") {
    content = <Message error text="Link inválido. Tente resetar sua senha novamente." />;
  } else {
    content = (
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Nova senha"
          type="password"
          id="password"
          autoComplete="off"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button type="submit" onClick={formik.handleSubmit} fullWidth variant="contained" color="secondary" className={classes.submit}>
          Confirmar
        </Button>
      </form>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recuperação de senha
        </Typography>
        {content}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
