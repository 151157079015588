import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../api";
import _ from "lodash";

const getClientSimple = (client) => _.pick(client, ["id", "slug", "name"]);
const traverse = function (visit, clients, path) {
  for (let c of clients) {
    const newPath = [...path, c.name];
    visit(c, newPath);
    traverse(visit, c.clients, newPath);
  }
};

const clientToOptions = (root) => {
  let options = [{ client: getClientSimple(root), path: [root.name] }];
  traverse((client, path) => options.push({ client: getClientSimple(client), path }), root.clients, [root.name]);
  return options;
};

export default function useClient() {
  let { client: slug } = useParams();
  const current = slug || "root"; //auth.impersonate;

  const { refetch, isLoading, isError, error, data: tree } = useQuery("client-tree", api.clientTree);

  if (isLoading) return { loading: true };
  if (isError) return { error: error };

  const [client, path] = tree && tree.meta[current];
  const fullPath = [...path, current];

  const clientOptions = clientToOptions(client);

  /*
  Example:
    path: ['globus', 'fk']   
    client: {id: 2, slug: 'fk', name: 'FK', info: {…}, clients: [...]}
    dir: {root: Array(2), globus: Array(2), fk: Array(2), fk-north: Array(2), fnk1: Array(2), …}
  */
  return { refetch, path: fullPath, client, dir: tree.meta, dirList: tree.metaList, currentSlug: current, clientOptions };
}
