import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { createStyles, createTheme, makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import * as React from "react";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        justifyContent: "left",
        "& .icon": {
          color: "inherit",
        },
      },
      Offline: {
        border: `1px solid`,
      },
      Online: {
        color: theme.palette.success.dark,
        border: `1px solid ${theme.palette.success.main}`,
      },
    }),
  { defaultTheme }
);

const Status = React.memo((props) => {
  const { status } = props;
  let icon = null;
  const classes = useStyles();
  if (status === "Online") {
    icon = <DoneIcon className="icon" />;
  }
  return <Chip className={clsx(classes.root, classes[status])} icon={icon} size="small" label={status} variant="outlined" />;
});

export function renderOnline(params) {
  return <Status status={params.value.toString()} />;
}
