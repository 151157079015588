import { useStyles } from "../pages/DeviceList";
import { Drawer } from "../components/Drawer";
import { CssBaseline, Grid } from "@material-ui/core";
import React, { useState } from "react";
import DashBoardDeviceItem from "../components/DashBoardItem/index.jsx";

const TEST_DATA = [
  {
    deviceName: "GL_1234",
    operationStatus: "Aquecimento",
    onlineStatus: true,
    temperature: "12",
    setpoint: "25",
    unit: "C",
  },

  {
    deviceName: "GL_1423",
    operationStatus: "Refrigeração",
    onlineStatus: true,
    temperature: "12",
    setpoint: "-10",
    unit: "C",
  },

  {
    deviceName: "GL_1334",
    operationStatus: "Defrost",
    onlineStatus: true,
    temperature: "02",
    setpoint: "2",
    unit: "C",
  },

  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },

  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },

  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: true,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: true,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: false,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
  {
    deviceName: "GL_1768",
    operationStatus: "Refrigeração",
    onlineStatus: true,
    temperature: "12",
    setpoint: "5",
    unit: "F",
  },
];

export const Wrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default function HomeDashboard() {
  return (
    <Wrapper>
      <div style={{ flex: 1, margin: 20 }}>
        <Grid container spacing={2}>
          {/* // TODO: paginação de itens */}
          {TEST_DATA.map((data) => (
            <DashBoardDeviceItem
              deviceName={data.deviceName}
              operationStatus={data.operationStatus}
              onlineStatus={data.onlineStatus}
              temperature={data.temperature}
              setpoint={data.setpoint}
              unit={data.unit}
            />
          ))}
        </Grid>
      </div>
    </Wrapper>
  );
}
