import React from "react";
import { MenuItem, withStyles, makeStyles, ListItemText } from "@material-ui/core";

import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import * as api from "../api";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

const makeMenuTree = (ref, onSelect, data, level = 0, showAdditionalInfo = true, selected = null) => {
  const deviceInfo = data && data.info && data.info.devices ? `${data.info.devices} devices` : "No devices";
  const clientInfo = data && data.info && data.info.children ? `${data.info.children} clients` : "No clients";

  const additionalInfo = `${clientInfo} · ${deviceInfo}`;

  return (
    <React.Fragment key={data.id}>
      <MenuItem ref={ref} key={data.id} onClick={() => onSelect(data.id)} selected={selected && selected == data.id} style={{ marginLeft: level * 20 }}>
        {showAdditionalInfo ? <ListItemText style={{ margin: 0 }} primary={data.name} secondary={additionalInfo} /> : data.name}
      </MenuItem>
      {data.clients.map((d) => makeMenuTree(ref, onSelect, d, level + 1, showAdditionalInfo, selected))}
    </React.Fragment>
  );
};

// const MenuTree = ({ selected, onSelect }) => {
const MenuTree = React.forwardRef((props, ref) => {
  const classes = useStyles();

  // const { isLoading, error, data } = useQuery("tree", () => api.tree("root"));
  const { isLoading, isError, error, data } = useQuery("client-tree", api.clientTree, { staleTime: 30000 });

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return makeMenuTree(ref, props.onSelect, data, 0, true, props.selected);

  //   return (
  //     <TreeView
  //       className={classes.root}
  //       defaultCollapseIcon={<ExpandMoreIcon />}
  //       defaultExpandIcon={<ChevronRightIcon />}
  //     >
  //       {makeTree(data)}
  //     </TreeView>
  //   );
});

export default MenuTree;
