import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";

import background from "../../assets/images/login_bg.jpg";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import { usePaginatedQuery, useQuery } from "react-query";
import { Box, Slider } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/ArrowUpward";
import { grey } from "@material-ui/core/colors";
import { useEffect } from "react";
import { parseISO, differenceInSeconds } from "date-fns";

// Buttons
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import DeviceControlFlex from "./Grid";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 20,
      maxWidth: 785,
    },
    media: {
      height: 180,
    },
    setpoint: {
      // maxWidth: 200,
      fontWeight: 900,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    controlIcon: {
      height: 38,
      width: 38,
    },
  })
);

const marks = [
  {
    value: 17,
    label: "17°C",
  },
  {
    value: 26,
    label: "26°C",
  },
  {
    value: 35,
    label: "35°C",
  },
];

const MAGIC_TEMP_NUMBER = 4597.0;

function toCelsius(f) {
  return (5 / 9) * (f - 32);
}
function setpointToCelsius(val) {
  const f = (val - MAGIC_TEMP_NUMBER) / 10.0;
  return Math.round(toCelsius(f)); // integer
}

function toFahrenheit(c) {
  return c * (9 / 5) + 32;
}

function celsiusToSetpoint(c) {
  return toFahrenheit(c) * 10.0 + MAGIC_TEMP_NUMBER;
}

// all this would come  from a protocol file...
// and widgets would be standard tings...
const LIMITS = {
  MIN: 17,
  MAX: 32,
};

const REFETCH_INTERVAL_MS = 10000; // TOOD: change back

export default function DeviceControl() {
  const classes = useStyles();
  let { serial } = useParams();

  const [remoteSetpoint, setRemoteSetpoint] = React.useState(0);
  const [localSetpoint, setLocalSetpoint] = React.useState(0); // back to zero
  const [localChanged, setLocalChanged] = React.useState(null); // TODO: reset when values match? or timeout?
  const [localParams, setLocalParams] = React.useState({});

  const { isLoading, error, data } = useQuery(
    ["device", serial],
    api.deviceDetail,
    {
      refetchInterval: REFETCH_INTERVAL_MS, // only changes when
    }
  );

  React.useEffect(() => {
    try {
      params = JSON.parse(data.read_params);
      setLocalParams(params);
      const newSetpoint = setpointToCelsius(params["Setpoint"]);
      const paramsChangedTs = parseISO(data.params_changed_at);
      console.table({
        newSetpoint,
        paramsChangedTs,
        localChanged,
        diff: differenceInSeconds(paramsChangedTs, localChanged),
      });

      if (
        localChanged == null ||
        differenceInSeconds(paramsChangedTs, localChanged) > 0
      ) {
        // If remote (eg: the panel) was updated after the React app, sync. overwrite the "local setpoint" value.
        setLocalSetpoint(newSetpoint);
      }
      setRemoteSetpoint(newSetpoint);
    } catch (error) {}
  }, [isLoading, error, data]);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  let params = null;
  // let setpoint = 0;
  // console.log(localSetpoint, remoteSetpoint, localSetpoint == remoteSetpoint);

  return (
    <div>
      <Typography variant="h3" color="textSecondary">
        Dispositivo {serial}
      </Typography>
      {true && (
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={background}
            title="Globus"
          />
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Dispositivo GlobusWay
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              MAC: {serial}
            </Typography>

            <Box m={2} mt={2}>
              <Box display="flex" justifyContent="center">
                <div className={classes.controls}>
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      setLocalChanged(new Date());
                      setLocalSetpoint(Math.min(localSetpoint + 1, LIMITS.MAX));
                    }}
                  >
                    <AddIcon
                      style={{ fill: grey[800] }}
                      className={classes.controlIcon}
                    />
                  </IconButton>
                  <Typography variant="h3" className={classes.setpoint}>
                    {localSetpoint} °C
                  </Typography>
                  <IconButton
                    aria-label="subtract"
                    onClick={() => {
                      setLocalChanged(new Date());
                      setLocalSetpoint(Math.max(localSetpoint - 1, LIMITS.MIN));
                    }}
                  >
                    <RemoveIcon
                      style={{ fill: grey[800] }}
                      className={classes.controlIcon}
                    />
                  </IconButton>
                </div>
              </Box>
              {remoteSetpoint != localSetpoint && (
                <div className={classes.controls}>
                  <Typography variant="h6">
                    Remote temperature: {remoteSetpoint} °C
                  </Typography>
                </div>
              )}

              {/* <Slider
            className={classes.slider}
            defaultValue={setpoint}
            step={1}
            marks={marks}
            // valueLabelDisplay="on"
            min={17}
            max={35}
          /> */}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    console.log(
                      "remoteWrite",
                      localSetpoint,
                      "=",
                      celsiusToSetpoint(localSetpoint)
                    );
                    api.remoteWrite("remotewrite", serial, {
                      Setpoint: celsiusToSetpoint(localSetpoint),
                    });
                  }} // write remote value
                >
                  Save
                </Button>
              </Box>
            </Box>
          </CardContent>
          {/* <CardActions>
        <Button size="small" color="primary">
          {isLoading ? "Loading..." : "Ready"}
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
        </Card>
      )}
      <Box pt={2}>
        <DeviceControlFlex params={localParams} />
      </Box>
    </div>
  );
}
