import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";

const useStyles = makeStyles({
  table: {
    backgroundColor: "#fafafa",
    padding: 14,
    margin: 4,
  },
  success: {
    backgroundColor: "#dff0d8",
  },
  error: {
    backgroundColor: "#f2dede",
  },
});

/**
 * cols: { label: string, value: string }[]
 * rows: array of maps
 */

export default function EmbedTable({ cols, rows }) {
  const classes = useStyles();

  const getClassName = (status) => {
    if (status === "success") {
      return classes.success;
    } else if (status === "error") {
      return classes.error;
    } else {
      return classes.pending;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            {cols.map(({ label }) => (
              <TableCell>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow className={getClassName(row.updateStatus)} key={row.label}>
              {cols.map(({ path, render }) => (
                <TableCell component="th" scope="row">
                  {render ? render(_.get(row, path, "")) : _.get(row, path, "")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
