import { makeStyles } from "@material-ui/core";

export const useDashBoardAboutItem = makeStyles((theme) => (
  {
  wrapper: {
    display: "flex",
    flex: 1,
    boxShadow: "1px 1px 5px 1px #5A6973",
    backgroundColor: "#fefefe",
    borderRadius: 6,
    height: "45%",
    justifyContent: "center",
    paddingLeft: 30,
    flexDirection: "row",
  },

  infoArea: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },

  infoDeviceName: {
    fontSize: 30,
    color: "#383C3F",
    fontWeight: "400",
    lineHeight: "normal",
  },

  infoTimeText: {
    fontSize: 15,
    color: "#5A6973",
    fontWeight: "400",
  },

  actuatorsArea: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fefefe",
    borderRadius: 12,
  },
}));
