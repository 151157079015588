import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import DeviceControl from "../components/DeviceControl/DeviceControl";
import { Drawer } from "../components/Drawer";
import * as api from "./api";
import _ from "lodash";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box, Chip, CircularProgress, Grid, Slider, SvgIcon } from "@material-ui/core";
import { parseISO, formatDistanceToNow } from "date-fns";
import differenceInMinutes from "date-fns/differenceInMinutes";
import Avatar from "@material-ui/core/Avatar";
import { blue, deepOrange, grey, red } from "@material-ui/core/colors";
import ChartTemp from "./components/ChartTemp";
import { ChipStatus } from "./components/ChipStatus";
import { Map as MapMultiple } from "./components/MapMultiple";
import { decodeSetpoint, getInitiais } from "./util";
import { Link as RouterLink } from "react-router-dom";
import { Wrapper, message } from "./components/Wrapper";
import { Loading } from "./components/Loading";

function IconThermostat(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9,16c0,1.656,1.344,3,3,3s3-1.344,3-3c0-0.353-0.072-0.686-0.184-1H9.184C9.072,15.314,9,15.647,9,16z" />
      <path d="M18,6V4h-3.185C14.401,2.839,13.302,2,12,2c-1.654,0-3,1.346-3,3v5.8c-1.842,1.072-3,3.048-3,5.2c0,3.309,2.691,6,6,6 s6-2.691,6-6c0-2.152-1.158-4.128-3-5.2V10h3V8h-3V6H18z M13.595,12.324C15.056,12.973,16,14.415,16,16c0,2.206-1.794,4-4,4 s-4-1.794-4-4c0-1.585,0.944-3.027,2.405-3.676L11,12.061V5c0-0.552,0.448-1,1-1s1,0.448,1,1v1v6.061L13.595,12.324z" />
    </SvgIcon>
  );
}

function SvgDevice(props) {
  return (
    <svg height="200" width="100">
      {/* <rect x="0" y="0" height="150" width="100" fill="rgba(255,0,0,0.1)" /> */}
      <circle stroke="#000" strokeWidth="3" fill="transparent" cx="50" cy="50" r="30" />
      <text fill="#000" fontSize="24" fontWeight="bold" fontFamily="sans-serif" x="50" y="53" textAnchor="middle" alignmentBaseline="middle">
        23
      </text>
      <text fill="#000" fontSize="18" fontWeight="bold" fontFamily="sans-serif" x="50" y="115" textAnchor="middle" alignmentBaseline="middle">
        23
      </text>
    </svg>
  );
}

const DeviceCard = (props) => {
  const classes = useStyles();
  const { serial, updated_at, updated_by, last_position, params } = props;
  const link = `/device/${serial}/`;

  const { chamber2, isOnline, setpoint1, setpoint2, temp1, temp2, controle } = decodeSetpoint(props);

  return (
    <Card style={{ height: "100%" }}>
      <CardActionArea component={RouterLink} to={link} style={{ height: "100%" }}>
        <CardContent style={{ padding: 0 }}>
          <Box flex={1} style={{ padding: 16 }}>
            <Box mb={1} display="flex" justifyContent="space-between" style={{ borderWidth: 1, borderColor: "black" }}>
              <Typography noWrap variant="h5">
                {serial}
              </Typography>
              <ChipStatus online={isOnline} />
            </Box>
            <Typography noWrap style={{ color: grey[600] }}>
              {controle || "--"}
            </Typography>
            <Typography noWrap style={{ color: grey[600] }}>
              {_.get(updated_by, "name", "\u00A0")}
            </Typography>
          </Box>

          <Box flexGrow={1} display="flex" flexDirection="row" justifyContent="center" style={{ backgroundColor: grey[100] }}>
            <Box m={2} display="flex" alignItems="center" justifyContent="center" flexDirection={"column"}>
              <Avatar className={classes.avatar}>{temp1}</Avatar>
              <Typography noWrap style={{ marginTop: 10, fontSize: 15, color: grey[500] }} align="center">
                Setpoint: <b>{setpoint1}</b>
              </Typography>
            </Box>
            {chamber2 && (
              <Box m={2} display="flex" alignItems="center" justifyContent="center" flexDirection={"column"}>
                <Avatar className={classes.avatar}>{temp2}</Avatar>
                <Typography noWrap style={{ marginTop: 10, fontSize: 15, color: grey[500] }} align="center">
                  Setpoint: <b>{setpoint2}</b>
                </Typography>
              </Box>
            )}
          </Box>

          {/* <Box mt={2} display="flex" justifyContent="space-between" style={{ borderWidth: 1, borderColor: "black" }}>
            <Typography noWrap style={{ fontSize: 13 }}>
              Atualizado: {updated_at}
            </Typography>
          </Box> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default function Device() {
  const classes = useStyles();

  const main = useQuery(["multi-data"], api.getMultiData, {
    refetchInterval: 10000,
  });

  if (main.isLoading) return <Loading />;
  if (main.error) return message("An error has occurred: " + main.error.message);

  let devices = _.get(main, "data.result");
  if (devices == null || devices.length == 0) {
    return message("Error: no device found.");
  }

  return (
    <Wrapper>
      <Typography gutterBottom variant="h5" component="div">
        {_.get(main.data, "result", []).length} devices
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MapMultiple {...main} />
        </Grid>
        {devices.map((device) => (
          <Grid key={device.serial} item xs={12} md={3}>
            <DeviceCard {...device} />
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "white",
    color: grey[500],
    fontWeight: "bold",
  },
}));
