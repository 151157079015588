import { Box, Button, CardActionArea } from "@material-ui/core";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";

const makeLinkOpen = (client) => "/client/" + client;

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function ClientCard({ id, slug, name, info }) {
  const { devices, children } = info;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={makeLinkOpen(slug)}>
        <CardContent>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
          <Typography color="textSecondary">{devices > 0 ? `${devices} devices` : "No devices"}</Typography>
          <Typography color="textSecondary">{children > 0 ? `${children} clients` : "No clients"}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
