import { makeStyles } from "@material-ui/core";

export const useDashBoardGraphs = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    boxShadow: "1px 1px 5px 1px #5A6973",
    borderRadius: 12,
    borderRadius: 6,
    flexDirection: "column",
    height: "100%",
  },
}));
