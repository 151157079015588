import { deepOrange } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../api";
import DeviceControl from "../components/DeviceControl/DeviceControl";
import { Drawer, Constants } from "../components/Drawer";

export default function Device() {
  const classes = useStyles();
  let { serial } = useParams();

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <Drawer /> */}
      <main className={classes.content}>
        <DeviceControl serial={serial} />
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${Constants.drawerWidth}px)`,
    marginLeft: Constants.drawerWidth,
  },
  drawer: {
    width: Constants.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: Constants.drawerWidth,
    backgroundColor: "#172026",
    color: "white",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey[400],
  },
  menuHeader: {
    color: "#bbb",
    // fontSize: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
}));
