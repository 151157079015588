import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { useEffect } from "react";
import { Box, FormHelperText, TextField } from "@material-ui/core";
import _ from "lodash";
import * as yup from "yup";
import { useFormik } from "formik";
import * as api from "../../api";
import useClient from "../../hooks/useClient";
import { EnumValue } from "./EnumValue";
import { toBreadcrumbs } from "./Breadlist";
import EmbedTable from "./EmbedTable";

const validateForm = yup.object({
  client: yup.string("Select a client for this user").required("Client is required"),
});
const validateMove = yup.object({
  client: yup.string("Select a client for this user").required("Client is required"),
});
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    // margin: "auto",
    minWidth: 400,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

// Convert error "Django format" to formik
const errorToFormik = (resp) => {
  if (Array.isArray(resp)) {
    return {
      others: resp.join(", "),
    };
  }

  let output = {};
  for (const k of Object.keys(resp)) {
    output[k] = typeof resp[k] == "string" ? resp[k] + ", " : resp[k].join(", ");
  }

  if (output["username"] && typeof output["username"] == "string") {
    output["email"] = output["username"].replace("username", "email");
  }
  return output;
};

export const DialogDeviceChange = ({ initial, isOpen, onClose, onSave }) => {
  const classes = useStyles();
  const { client, clientOptions } = useClient();

  const formik = useFormik({
    initialValues: {
      serial: "",
      client: "",
    },
    validationSchema: validateForm,
    onSubmit: async (values, { setErrors }) => {
      try {
        const { serial, client } = values;
        if (initial.client != client) {
          console.log("new client", serial, client);
          await api.moveDevice(serial, client);
        }
        onClose();
      } catch (error) {
        console.log("got error", errorToFormik(error.response.data));
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (initial) {
      formik.setValues({ ...initial });
    }
    console.log("initial", initial);
  }, [isOpen]);

  if (!clientOptions) return null; // for now

  //   return <pre>{JSON.stringify(clientOptions, null, 4)}</pre>;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // onKeyUp={onKeyUp}
    >
      <DialogTitle>{"Editar dispositivo"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            disabled={true}
            margin="normal"
            fullWidth
            autoComplete="off"
            id="serial"
            name="serial"
            label="Serial"
            value={formik.values.serial}
            onChange={formik.handleChange}
            error={formik.touched.serial && Boolean(formik.errors.serial)}
            helperText={formik.touched.serial && formik.errors.serial}
          />
          <EnumValue
            id="client"
            name="client"
            label="Cliente"
            // options={clientOptions.map((c) => ({ value: c.slug, display: toBreadcrumbs([...c.pathNames, c.name]) }))}
            // options={clientOptions.map((c) => ({ value: c.slug, display: c.name }))}
            options={clientOptions.map((c) => ({ value: c.client.id, display: toBreadcrumbs(c.path) }))}
            value={formik.values.client}
            onChange={(value) => formik.setFieldValue("client", value)}
            error={formik.touched.client && Boolean(formik.errors.client)}
            helperText={formik.touched.client && formik.errors.client}
          />

          {formik.errors.others && <FormHelperText error={true}>{formik.errors.others}</FormHelperText>}
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
          Atualizar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogBulkMoveDevice = ({ isOpen, deviceList, onClose }) => {
  const classes = useStyles();
  const { clientOptions } = useClient();
  const [status, setStatus] = useState("initial");
  const [localDeviceList, setLocalDeviceList] = useState([]);

  const updateSingleDevice = (serial, status) => setLocalDeviceList((prev) => prev.map((d) => (d.serial === serial ? { ...d, updateStatus: status } : d)));

  const formik = useFormik({
    initialValues: {
      client: "", // id
    },
    validationSchema: validateMove,
    onSubmit: async (values, { setErrors }) => {
      try {
        setStatus("update");
        for (const { serial } of deviceList) {
          try {
            await api.moveDevice(serial, values.client);
            updateSingleDevice(serial, "success");
          } catch (error) {
            updateSingleDevice(serial, "error");
          }
        }
        setStatus("done");
      } catch (error) {
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setStatus("initial");
      setLocalDeviceList([...deviceList.map((d) => ({ ...d, updateStatus: "pending" }))]);
    }
  }, [isOpen, deviceList]);

  if (!clientOptions) return null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{`Mover ${deviceList?.length} dispositivos`}</DialogTitle>
      <DialogContent>
        <Box mb={2} style={{ maxHeight: 300, overflow: "scroll" }}>
          <EmbedTable
            cols={[
              { label: "Dispositivo", path: "serial" },
              { label: "Cliente atual", path: "client_detail.name" },
              { label: "Status", path: "updateStatus", render: (status) => (status === "pending" ? "Pendente" : status === "error" ? "Erro" : "Sucesso") },
            ]}
            rows={localDeviceList}
          />
        </Box>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <EnumValue
            disabled={status != "initial"}
            id="client"
            name="client"
            label="Novo cliente"
            options={clientOptions.map((c) => ({ value: c.client.id, display: toBreadcrumbs(c.path) }))}
            value={formik.values.client}
            onChange={(value) => formik.setFieldValue("client", value)}
            error={formik.touched.client && Boolean(formik.errors.client)}
            helperText={formik.touched.client && formik.errors.client}
          />
        </form>
      </DialogContent>
      <DialogActions style={{ marginTop: 10, marginBottom: 15 }}>
        {status == "initial" && (
          <Button disabled={false} onClick={formik.handleSubmit} variant="contained" color="secondary">
            Mover
          </Button>
        )}
        {status !== "initial" && (
          <Button disabled={status == "update"} onClick={onClose} variant="contained" color="secondary">
            {status == "done" ? "Fechar" : "Atualizando"}
          </Button>
        )}
        {status == "initial" && (
          <Button disabled={status == "update"} onClick={onClose} color="primary">
            Cancelar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
