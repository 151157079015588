import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { format } from "date-fns";

import { grey } from "@material-ui/core/colors";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: grey[50],
  },
  selected: {
    backgroundColor: grey[100],
  },
  selectedText: {
    color: theme.palette.secondary.main,
  },
}));

const fmtTime = (date) => format(date, "HH:mm:ss");

const removeLastThreeCharacters = (str) => str.substring(0, str.length - 3);

const titleFromEntries = (entries) => {
  const first = entries[0];
  const last = entries[entries.length - 1];
  if (entries.length == 1) return removeLastThreeCharacters(first.label);
  return `${removeLastThreeCharacters(first.label)} - ${removeLastThreeCharacters(last.label)}`;
};

const TEN_MINUTES_IN_MS = 1000 * 60 * 10;

const dayToSections = (day) => {
  const TIMESTAMP_KEY = "localts";
  if (!day || day.length == 0) return [];

  let last = null;
  let sections = [];
  let currentEntries = [];

  for (let i = 0; i < day.length; i++) {
    const row = day[i];
    const ts = row[TIMESTAMP_KEY];
    if (last != null) {
      const diff = Math.abs(ts - last);
      if (diff > TEN_MINUTES_IN_MS) {
        if (currentEntries) {
          sections.unshift({ label: titleFromEntries(currentEntries), entries: currentEntries });
        }
        currentEntries = [];
      }
    }
    last = ts;
    currentEntries.unshift({ index: i, label: fmtTime(new Date(row[TIMESTAMP_KEY])) });
  }

  sections.unshift({ label: titleFromEntries(currentEntries), entries: currentEntries });

  return sections;
};

export default ({ day, onClick }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState({});
  const [sections, setSections] = React.useState(null);
  const [selectedTimeIndex, setSelectedTimeIndex] = React.useState(null);

  const sectionClick = (index) => {
    // setOpen({ ...open, [index]: !open[index] });
    setOpen({ [index]: !open[index] }); // close others
  };

  React.useEffect(() => {
    setSections(dayToSections(day));
    setOpen({});
  }, [day]);

  if (!sections) return "loading";

  return (
    <List component="nav" className={classes.root}>
      {sections.map((section, index) => (
        <div key={index}>
          <ListItem button onClick={() => sectionClick(index)}>
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary={section.label} />
            {open[index] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {section.entries.map((entry, key) => (
                <ListItem
                  key={key}
                  button
                  onClick={() => {
                    setSelectedTimeIndex(entry.index);
                    onClick(day[entry.index]);
                  }}
                  className={clsx(classes.nested, selectedTimeIndex === entry.index ? classes.selected : null)}
                >
                  <ListItemText className={selectedTimeIndex === entry.index ? classes.selectedText : null} primary={entry.label} />
                  {selectedTimeIndex === entry.index && (
                    <ListItemSecondaryAction>
                      <ChevronRightOutlinedIcon color={"secondary"} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
};
