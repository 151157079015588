import { Grid } from "@material-ui/core";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import PinMap from "./PinMap.png";
import { useTimeredGetRequest } from "../../../hooks/useTimeredRequest";
import { getLastLocationById } from "../../../api-globusway/api";

const positionDefault = ["0", "0"];

function DetailMap({ serial }) {
  //TODO: handle function to get Location from DataBase.

  const lastLocationResponse = useTimeredGetRequest(getLastLocationById, "b7fc4004-335d-4bc8-a98b-510367d45af9", 30);
  const position = lastLocationResponse.lastLocation ? lastLocationResponse.lastLocation.split("/") : positionDefault;

  // console.log(position);
  // const position = ["-29.994096", "-51.194651"];
  // console.log("lastLocation", position);
  const Pin = new L.icon({
    iconUrl: PinMap,
    iconRetinaUrl: PinMap,
    iconSize: [150 / 4, 201 / 4],
    popupAnchor: [0, -201 / 8],
  });

  return (
    <Grid item xs={12} sm={12} md={12} style={{ boxShadow: "1px 1px 5px 1px #5A6973" }}>
      {lastLocationResponse.lastLocation && (
        <MapContainer style={{ height: 500 }} center={position} zoom={15} minZoom={3} touchZoom>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          <Marker position={position} icon={Pin}>
            <Popup>
              <div>
                <b style={{ fontSize: 16 }}>{serial}</b>
              </div>
              <div style={{ marginTop: 10 }}>
                Last recorded position
                <b> </b>
              </div>
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </Grid>
  );
}
//https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
//https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png

export default DetailMap;
