import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { Avatar, Box, ButtonBase, Button, Divider, Menu, MenuItem, withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { deepOrange } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import UserIcon from "@material-ui/icons/PersonOutline";
import ClientIcon from "@material-ui/icons/BusinessOutlined";
import DeviceIcon from "@material-ui/icons/LocalShippingOutlined";
import AlertIcon from "@material-ui/icons/ReportProblemOutlined";

//import Cookies from "js-cookie";

import * as api from "../api";
import MenuTree from "../components/MenuTree";
import DeviceCard from "../components/DeviceCard";

import { usePaginatedQuery, useQuery } from "react-query";

import React from "react";
import { useEffect } from "react";
import { useAuthContext } from "../hooks/useAuth";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#172026",
    color: "white",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey[400],
  },
  menuHeader: {
    color: "#bbb",
    // fontSize: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const listStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    "&:hover": {
      // backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[100],
    },
  },
  selected: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
      color: "white",
      // fontWeight: 900,
      // "&:hover, &:focus": {
      //   backgroundColor: theme.palette.grey[600],
      // },
    },
  },
  button: {
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const iconStyles = makeStyles((theme) => ({
  root: {
    color: "inherit",
  },
}));

const DataDisplay = () => {
  const { isLoading, error, data } = useQuery("users", api.users().getAll);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      {data.results.map((x) => (
        <p key={x.username}>{x.username}</p>
      ))}
    </div>
  );
};

const DevicesDisplay = ({ serial }) => {
  const [page, setPage] = React.useState(0);
  const { auth } = useAuthContext();

  const { isLoading, isError, error, resolvedData } = usePaginatedQuery(["devices", page, auth.impersonate], api.devicesList, {
    refetchInterval: 3000,
    refetchIntervalInBackground: true, // tab in background
  });

  // console.log(resolvedData);

  // resolvedData.sort()

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error: {error.message}</div>
      ) : (
        // `resolvedData` will either resolve to the latest page's data
        // or if fetching a new page, the last successful page's data
        <div>
          {resolvedData.results.map((device) => (
            <Box display="inline-block" pr={2} pb={2}>
              <DeviceCard device={device} />
            </Box>
          ))}
        </div>
      )}
      <span>Current Page: {page + 1}</span>
      <button onClick={() => setPage((old) => Math.max(old - 1, 0))} disabled={page === 0}>
        Previous Page
      </button>{" "}
      <button
        onClick={() => {
          // console.log("latestData", latestData);
          // Here, we use `latestData` so the Next Page
          // button isn't relying on potentially old data
          // setPage((old) => (!latestData || !latestData.next ? old : old + 1));
        }}
      >
        Next Page
      </button>
      {/* {
        // Since the last page's data potentially sticks around between page requests,
        // we can use `isFetching` to show a background loading
        // indicator since our `status === 'loading'` state won't be triggered
        isFetching ? <span> Loading...</span> : null
      }{" "} */}
    </div>
  );
};

export default function PermanentDrawerLeft() {
  const classes = useStyles();
  const listClasses = listStyles();
  const iconClasses = iconStyles();
  let history = useHistory();
  const { clear } = useAuthContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  let { id } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    // Cookies.remove("token");
    clear();
    history.push("/");
    // window.location.replace("/");
  };

  // useEffect(() => {
  //   api
  //     .users()
  //     .getAll()
  //     .then(({ data: { results } }) => console.log(results));

  //   api
  //     .clients()
  //     .getAll()
  //     .then(({ data: { results } }) => console.log(results));

  //   api
  //     .devices()
  //     .getAll()
  //     .then(({ data: { results } }) => console.log(results));
  // }, []);

  const SidebarItem = ({ label, Icon, selected }) => (
    <ListItem button key={label} classes={listClasses} selected={selected}>
      <ListItemIcon style={{ color: "inherit", minWidth: 40 }}>
        <Icon classes={iconClasses} />
      </ListItemIcon>
      <ListItemText primary={label} style={{ marginTop: 6 }} />
    </ListItem>
  );

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      marginTop: 10,
      minWidth: drawerWidth,
    },
  })((props) => (
    <Menu
      elevation={3}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionProps={{
        timeout: 0,
      }}
      MenuListProps={{
        style: {
          paddingBottom: 0,
        },
      }}
      {...props}
    />
  ));

  return (
    <div className={classes.root}>
      <CssBaseline />
      {false && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              Permanent drawer
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        {/* <div className={classes.toolbar} /> */}
        <ButtonBase aria-controls="user-menu" aria-haspopup="true" style={{ display: "inline" }} onClick={handleClick}>
          <Box px={2} pt={2} display="flex" flexDirection="row" alignItems="center">
            <Avatar className={classes.orange}>HS</Avatar>
            <Box ml={1.2} display="flex" flexDirection="column" alignItems="flex-start">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h6" className={classes.title}>
                  Hugo Schmitt
                </Typography>
                <KeyboardArrowDown style={{ color: "white" }} />
              </Box>
              <Typography className={classes.subtitle}>CLIENTE: root</Typography>
            </Box>
          </Box>
        </ButtonBase>
        <StyledMenu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <Typography className={classes.menuHeader}>CLIENTES</Typography>
          <MenuTree
            onSelect={(id) => {
              console.log("Client selected: " + id);
              handleClose();
            }}
          />
          <Divider />
          <MenuItem onClick={handleClose}>User settings</MenuItem>
          <MenuItem onClick={handleClose}>API Keys</MenuItem>
          <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </StyledMenu>
        {/* <Divider /> */}
        <Box ml={1}>
          <List>
            <SidebarItem Icon={UserIcon} label="Users" selected />
            <SidebarItem Icon={ClientIcon} label="Clients" />
            <SidebarItem Icon={DeviceIcon} label="Devices" />
            <SidebarItem Icon={AlertIcon} label="Alerts" />
          </List>
        </Box>
      </Drawer>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {/* <h1>Users</h1>
        <DataDisplay /> */}
        <h1>Devices</h1>
        <DevicesDisplay />
      </main>
    </div>
  );
}
