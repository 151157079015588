import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useClient from "../hooks/useClient";

export default () => {
  const { loading, error, path, dir } = useClient();

  if (loading) return null;
  if (error) return <div>{error}</div>;

  const mklink = (target) => {
    return target == null ? `/client` : `/client/${target}`;
  };

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {path.map((clientSlug, idx) => (
        <Link key={clientSlug} color={idx === path.length - 1 ? "textPrimary" : "textSecondary"} component={RouterLink} to={mklink(clientSlug)}>
          {dir[clientSlug][0].name || clientSlug}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
