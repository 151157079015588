import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { lighten, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import * as api from "../api";
import ClientBreadcrumbs from "../components/ClientBreadcrumbs";
import { Drawer as MenuLateral } from "../components/Drawer";
import useClient from "../hooks/useClient";

const validationSchema = yup.object({
  email: yup.string("Enter an email address").required("Email is required"),
  password: yup.string().required("Please Enter your password"),
});

// Convert error "Django format" to formik
const errorToFormik = (resp) => {
  let output = {};
  for (const k of Object.keys(resp)) {
    output[k] = resp[k].join(", ");
  }
  output["email"] = output["username"].replace("username", "email");
  return output;
};

const NewUserForm = ({ parent }) => {
  const { slug, name } = parent || { slug: null, parent: null };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const newUser = { ...values, parent: slug, username: values.email };
        const response = await api.users().create(newUser);
        console.log(response);
        // TODO: close form ? return to list ?
      } catch (error) {
        setErrors(errorToFormik(error.response.data));
      }
    },
  });

  return (
    <div>
      <Box mt={1} mb={1}>
        <ClientBreadcrumbs />
      </Box>
      <Typography variant="h4" component="h4" gutterBottom>
        Add user for client <b>{name}</b>
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          autoComplete="off"
          id="email"
          name="email"
          label="User's email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          autoComplete="off"
          id="first_name"
          name="first_name"
          label="First name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
        />

        <TextField
          fullWidth
          autoComplete="off"
          id="last_name"
          name="last_name"
          label="Last name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
        />

        <TextField
          fullWidth
          autoComplete="off"
          id="password"
          type="password"
          name="password"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Button color="primary" variant="contained" fullWidth type="submit">
          Create
        </Button>
      </form>
    </div>
  );
};

export default function Device() {
  const classes = useStyles();
  let { client, isLoading, isError } = useClient();

  // React.useEffect(() => {
  //   console.log({ client, isLoading, isError });
  // }, [client, isLoading, isError]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuLateral />
      <Box m={3}>{!isLoading && !isError && <NewUserForm parent={client} />}</Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .super-app-theme--Online": {
      backgroundColor: theme.status.online,
      "&:hover": {
        backgroundColor: lighten(theme.status.online, 0.1),
      },
    },
  },
}));
