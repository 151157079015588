import React, { useEffect, useState } from "react";
import { Wrapper } from "./HomeDashboard";
import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import AboutDevice from "../components/DashBoardDetail/AboutDevice";
import OperationStatus from "../components/DashBoardDetail/OperationStatus";
import DetailMap from "../components/DashBoardDetail/Map";
import ThermalControlCard from "../components/DashBoardDetail/ThermalControl";
import DashBoardGraphs from "../components/DashBoardDetail/Graphs";
import { getAllDataById } from "../api-globusway/api";

export default function DeviceDashboard() {
  let { serial } = useParams();

  return (
    <Wrapper>
      <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30 }}>
        <DetailMap serial={serial} />
        <Grid container direction="row" spacing={4} style={{ height: 360, paddingTop: 15, marginTop: 5 }}>
          <Grid item sm={12} md={6} lg={3} style={{ height: "108%" }}>
            <AboutDevice serial={serial} time="3 horas" />
            <OperationStatus serial={serial} status={6} />
          </Grid>
          <ThermalControlCard />
          <DashBoardGraphs />
        </Grid>
      </div>
    </Wrapper>
  );
}
