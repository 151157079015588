import React from "react";
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect, useParams } from "react-router-dom";
//import Cookies from "js-cookie";
import { useQuery, QueryCache, ReactQueryCacheProvider } from "react-query";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import { AuthProvider, useAuthContext } from "./hooks/useAuth";
import { ptBR } from "@material-ui/core/locale";

import * as Pages from "./pages";
import * as FK from "./pages-fk";
import * as GlobusWayPages from "./pages-globusway";
import env from "./env";

const defaultTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#172026", //grey[700],
      },
      secondary: {
        main: orange[800],
      },
    },
    status: {
      online: green[50],
    },
    drawer: {
      background: "#172026",
    },
  },
  ptBR
);

const fk = createTheme(
  {
    palette: {
      primary: {
        main: orange[800],
      },
      secondary: {
        main: grey[600],
      },
    },
    status: {
      online: green[50],
    },
    drawer: {
      background: "#0B4FA3",
    },
  },
  ptBR
);

const THEMES = {
  default: defaultTheme,
  fk: fk,
};

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useAuthContext();

  const loggedIn = auth && auth.token == "13";
  return <Route {...rest} render={(props) => (loggedIn ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />;
};

const queryCache = new QueryCache();

export default function App() {
  const [theme, setTheme] = React.useState("default");


  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <AuthProvider>
        <ThemeProvider theme={THEMES[theme]}>
          <Router>
            <div>
              <Switch>
                <Route exact path="/">
                  <Pages.Login />
                </Route>
                {/* Password reset */}
                <Route exact path="/reset-password-1">
                  <Pages.AskResetPassword />
                </Route>
                <Route exact path="/reset-password">
                  <Pages.ResetPassword />
                </Route>
                <PrivateRoute exact path="/device" component={GlobusWayPages.HomeDashboard} />
                <PrivateRoute path="/device/:serial" component={GlobusWayPages.DeviceDashboard} />
            
            
            
                <PrivateRoute exact path="/user" component={FK.UserTable} />
                <PrivateRoute exact path="/clients" component={FK.ClientTable} />
                <PrivateRoute exact path="/client" component={FK.ClientTable} />
                <PrivateRoute exact path="/devicemanage" component={FK.DeviceTable} />
          
                <PrivateRoute exact path="/client/:client/clients" component={Pages.ClientList} />
                <PrivateRoute exact path="/client/:client" component={Pages.ClientDetail} />
                <PrivateRoute exact path="/clients" component={Pages.ClientList} />
                <PrivateRoute exact path="/user/new" component={Pages.UserAdd} />
                <PrivateRoute exact path="/alert" component={Pages.ClientList} /> 
                <PrivateRoute exact path="/xxx/" component={Pages.DeviceList} />
  
                <PrivateRoute exact path="/client/:client/clients" component={Pages.ClientList} />
                <PrivateRoute exact path="/client/:client" component={Pages.ClientDetail} />
                <PrivateRoute exact path="/clients" component={Pages.ClientList} />
                <PrivateRoute exact path="/user/new" component={Pages.UserAdd} />
                <PrivateRoute exact path="/alert" component={Pages.ClientList} />
              </Switch>
            </div>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </ReactQueryCacheProvider>
  );
}
