import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";
import * as api from "../api";

import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles } from "@material-ui/core/styles";
import { SimpleAppbar } from "../components/DevicesAppBar";
import { Drawer } from "../components/Drawer";
import useClient from "../hooks/useClient";

import MUIDataTable from "mui-datatables";
import Breadlist from "./components/Breadlist";
import CustomToolbarSelect from "./components/CustomToolbarSelect";

import { DialogClientCreate, DialogClientChange } from "./components/ClientForms";

const tableColumns = [
  {
    name: "name",
    label: "Nome",
  },
  {
    name: "info.users",
    label: "Usuários",
  },
  {
    name: "info.devices",
    label: "Dispositivos",
  },
  {
    name: "info.children",
    label: "Clientes (filhos)",
  },
  {
    name: "pathNames",
    label: "Cliente pai",
    options: {
      customBodyRender: (value) => <Breadlist data={value} empty="(Nenhum)" />,
    },
  },
];

const TITLE = "Clientes";

export default () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const { refetch, loading, error, client, dirList } = useClient();

  React.useEffect(() => {
    console.log({ dirList });
    if (dirList) {
      if (value) {
        if (value.slug) {
          setOptions(dirList.filter((x) => x.slug != value.slug)); // ie: cannot be parent of itself
        } else {
          setOptions(dirList);
        }
      }
    }
  }, [dirList, value]);

  let content;
  if (loading) {
    content = <div>Loading...</div>;
  } else if (error) {
    content = <div>Error: {error.message}</div>;
  } else if (dirList) {
    // content = <pre>{JSON.stringify(dirList, null, 4)}</pre>;
    content = (
      <MUIDataTable
        title={TITLE}
        data={dirList}
        columns={tableColumns}
        options={{
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
              selectedRows={selectedRows}
              displayData={displayData}
              setSelectedRows={setSelectedRows}
              transfer={{
                label: "Mover clientes",
                onClick: ({ data }) => console.log("onMove", data),
              }}
              remove={{
                label: "Remover clientes",
                onClick: ({ data }) => console.log("onDelete", data),
              }}
            />
          ),
          enableNestedDataAccess: ".", // lodash's get style
          selectableRows: "none",
          rowsPerPage: 100,
          print: false,
          onRowClick: (r, meta) => {
            console.log("onRowClick", meta.dataIndex, dirList[meta.dataIndex]);
            setValue(dirList[meta.dataIndex]);
          },
        }}
      />
    );
  } else {
    <div>
      <div>Invalid data</div>
      <pre>{JSON.stringify(client, null, 4)}</pre>
    </div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer />
      <main className={classes.content}>
        <SimpleAppbar title={TITLE} buttons={[{ label: "Novo cliente", onClick: () => setValue({}) }]} />
        <DialogClientChange
          initial={value}
          options={options}
          isOpen={value != null}
          onClose={() => {
            refetch();
            setValue(null);
          }}
        />
        <DialogClientCreate
          isOpen={value != null && value.slug == null}
          options={options}
          onClose={() => {
            refetch();
            setValue(null);
          }}
        />
        <div className={classes.list}>{content}</div>
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    margin: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
}));
