import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

const getBreadcrumbColor = (isLast) => (isLast ? grey[700] : grey[500]);

export default ({ data, empty }) => {
  if (!data || data.length == 0) return <Typography style={{ color: grey[500] }}>{empty || "--"}</Typography>;
  return (
    <Breadcrumbs>
      {data.map((item, key) => (
        <Typography key={key} style={{ color: getBreadcrumbColor(key == data.length - 1) }}>
          {item}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export const toBreadcrumbs = (val) => {
  // return val.join("-");
  return (
    <Breadcrumbs>
      {val &&
        val.map((item, key) => (
          <Typography key={key} style={{ color: getBreadcrumbColor(key == val.length - 1) }}>
            {item}
          </Typography>
        ))}
    </Breadcrumbs>
  );
};
