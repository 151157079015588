import { Avatar, Box, ButtonBase, Divider, ListSubheader, MenuItem } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClientIcon from "@material-ui/icons/BusinessOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import DeviceIcon from "@material-ui/icons/LocalShippingOutlined";
import UserIcon from "@material-ui/icons/PersonOutline";
import AlertIcon from "@material-ui/icons/ReportProblemOutlined";
//import Cookies from "js-cookie";
import React from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import MenuTree from "../MenuTree";
import { SidebarItem } from "./SidebarItem";
import { StyledMenu } from "./StyledMenu";
import Constants from "./Constants";
import { avatar, displayName, useAuthContext } from "../../hooks/useAuth";
import { stringToColor } from "../../common/colors";

import * as Logos from "../../assets/images/brands";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${Constants.drawerWidth}px)`,
    marginLeft: Constants.drawerWidth,
  },
  drawer: {
    width: Constants.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: Constants.drawerWidth,
    backgroundColor: theme.drawer.background,
    color: "white",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  title: {
    fontSize: 15,
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey[400],
  },
  menuHeader: {
    color: "#bbb",
    // fontSize: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const Logo = ({ client }) => {
  const slug = client;
  const margin = 25;
  return (
    <div style={{ marginTop: margin, marginLeft: margin, marginRight: margin }}>
      <img style={{ width: Constants.drawerWidth - margin * 2 }} src={Logos[slug] || Logos["globus"]} alt={`${slug} logo`} />
    </div>
  );
};

export default function MyDrawer() {
  const classes = useStyles();
  let history = useHistory();
  const { setClear } = useAuthContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  let { id } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    // Cookies.remove("token");
    setClear();
    history.push("/");
    // window.location.replace("/");
  };

  let { serial } = useParams();
  const match = useRouteMatch();
  const { auth, setImpersonate } = useAuthContext();

  let client = auth.client;
  // if (auth.impersonate && auth.impersonate != auth.client) {
  //   client = auth.impersonate + " [i]";
  // }
  // console.log("match", match, auth);

  return (
    <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="left">
      {/* <div className={classes.toolbar} /> */}
      <Logo client={auth.client} />
      <ButtonBase aria-controls="user-menu" aria-haspopup="true" style={{ display: "inline" }} onClick={handleClick}>
        <Box px={2} pt={2} display="flex" flexDirection="row" alignItems="center">
          <Avatar atar style={{ backgroundColor: stringToColor(auth.username) }}>{avatar(auth)}</Avatar>
          <Box ml={1.2} display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" className={classes.title}>
                {displayName(auth)}
              </Typography>
              <KeyboardArrowDown style={{ color: "white" }} />
            </Box>
            {auth.client && <Typography className={classes.subtitle}>CLIENT: {client}</Typography>}
          </Box>
        </Box>
      </ButtonBase>
      <StyledMenu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <Typography className={classes.menuHeader}>SUB-CLIENTS</Typography> */}
        {/* <MenuTree
          selected={auth.impersonate}
          onSelect={(id) => {
            console.log("Client selected: " + id);
            setImpersonate(id);
            handleClose();
          }}
        /> */}
        {/* <Divider /> */}
        {/* <MenuItem onClick={handleClose}>User settings</MenuItem> */}
        {/* <MenuItem onClick={handleClose}>API Keys</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sair</MenuItem>
      </StyledMenu>
      {/* <Divider /> */}
      <Box ml={1}>
        <List>
          {/* <SidebarItem selected={match.path === "/device"} href={"/device"} Icon={DashboardIcon} label="Dashboard" /> */}
          {/* <ListSubheader style={{ color: "inherit" }}>Gerenciamento</ListSubheader>
          <SidebarItem selected={match.path === "/user"} href={"/user"} Icon={UserIcon} label="Usuários" />
          <SidebarItem selected={match.path === "/clients"} href={"/clients"} Icon={ClientIcon} label="Clientes" />
          <SidebarItem selected={match.path === "/devicemanage"} href={"/devicemanage"} Icon={DeviceIcon} label="Dispositivos" />
          <SidebarItem selected={match.path === "/alert"} href={"/alert"} Icon={AlertIcon} label="Alerts" /> */}
        </List>
      </Box>
    </Drawer>
  );
}
