import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

const listStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    "&:hover": {
      // backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[100],
    },
  },
  selected: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
      color: "white",
      // fontWeight: 900,
      // "&:hover, &:focus": {
      //   backgroundColor: theme.palette.grey[600],
      // },
    },
  },
  button: {
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const iconStyles = makeStyles((theme) => ({
  root: {
    color: "inherit",
  },
}));

export const SidebarItem = ({ label, Icon, selected, href }) => {
  const history = useHistory();
  const listClasses = listStyles();
  const iconClasses = iconStyles();

  return (
    <ListItem onClick={() => history.push(href)} button key={label} classes={listClasses} selected={selected}>
      <ListItemIcon style={{ color: "inherit", minWidth: 40 }}>
        <Icon classes={iconClasses} />
      </ListItemIcon>
      <ListItemText primary={label} style={{ marginTop: 6 }} />
    </ListItem>
  );
};

// export const SidebarItem = withRouter(SidebarItem_);
