import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Drawer } from "../../components/Drawer";
import Typography from "@material-ui/core/Typography";

export const Wrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export const message = (msg) => {
  return (
    <Wrapper>
      <Typography gutterBottom variant="h6" component="div">
        {msg}
      </Typography>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));
