import axios from "axios";

const baseUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "http://localhost:3000/";

const client = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
  },
});

function getHeaders() {
  const authJSON = localStorage.getItem("auth");
  let token;

  if (authJSON) {
    const auth = JSON.parse(authJSON);
    token = auth.token;
  }

  if (!token) {
    console.log("no Token present!?");
    return;
  }
  let output = {
    Authorization: `Token ${token}`,
  };
  return output;
}
export const deviceDetail = async (key, serial) => {
  const { data } = await client.get(`devices/${serial}/`, {
    headers: getHeaders(),
  });
  return data;
};

const params = [
  "HabilitaSensorTemperaturaCamara2",
  "Setpoint01",
  "Setpoint02",
  "TemperaturaCamara1",
  "TemperaturaCamara2",
  "TipoDeControleShort",
  "TipoDeControleString",
  "EstadoControle",
  "TempoParaDegelo",
  "NumeroSerial",
];

export const getMultiData = async (key, serial) => {
  const { data } = await client.post(
    `fk/bulk-query/`,
    { params },
    {
      headers: getHeaders(),
    }
  );
  return data;
};

export const getMainScreenData = async (key, serial) => {
  const { data } = await client.post(
    `fk/bulk-query/`,
    { serial: [serial], params },
    {
      headers: getHeaders(),
    }
  );
  return data;
};

export const getPendingRemote = async (key, serial) => {
  const { data } = await client.get(`fk/remote-cmd/`, { params: { serial } }, { headers: getHeaders() });
  return data;
};

export const getAllParams = async (key, serial) => {
  const { data } = await client.post(`fk/bulk-query/`, { serial: [serial], full: true }, { headers: getHeaders() });
  return data;
};

export const getHistory = async (key, serial) => {
  const { data } = await client.post(
    `fk/history/`,
    { serial: serial, fields: ["event", "position", "remote_command"] },
    {
      headers: getHeaders(),
    }
  );
  return data;
};

export const getParamHistory = async (key, serial, paramArray) => {
  const { data } = await client.post(
    `fk/param-history/`,
    { serial: serial, params: paramArray },
    {
      headers: getHeaders(),
    }
  );
  return data;
};

export const getLogFiles = async (key, serial) => {
  const { data } = await client.get(`fk/logs/${serial}/`, { headers: getHeaders() });
  return data;
};

export const remoteCommand = async (key, serial, param, value) => {
  const { data } = await client.post(`fk/remote-cmd/`, { serial: [serial], param, value }, { headers: getHeaders() });
  return data;
};

export const downloadFile = async (url) => {
  const { data } = await client.get(url, {
    headers: getHeaders(),
  });
  return data;
};
