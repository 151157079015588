import { Box, Button } from "@material-ui/core";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { parseISO, formatDistanceToNow } from "date-fns";
import differenceInMinutes from "date-fns/differenceInMinutes";

const makeLink = (device) => "/device/" + device.serial;

function toCelsius(f) {
  return (5 / 9) * (f - 32);
}
const MAGIC_TEMP_NUMBER = 4597.0;
function setpointToCelsius(val) {
  const f = (val - MAGIC_TEMP_NUMBER) / 10.0;
  return toCelsius(f);
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function DeviceCard({ device }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const { serial, updated_at, client, read_params } = device;
  let setpoint = "Not avail.";
  try {
    const params = JSON.parse(read_params);
    if ("Setpoint" in params) {
      setpoint = Math.round(setpointToCelsius(params["Setpoint"])) + "°C";
    }
  } catch (error) {
    console.log("Could not parse json");
  }

  console.log("ISONLINE", { updated_at: parseISO(updated_at) });

  const isOnline = differenceInMinutes(new Date(), parseISO(updated_at)) <= 10;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Dispostivo
        </Typography>
        <Typography variant="h5" component="h2">
          {serial}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Setpoint: {setpoint}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Status: {isOnline ? "Online" : "Offline"}
        </Typography>

        <Typography className={classes.pos} color="textSecondary">
          Last seen {formatDistanceToNow(parseISO(updated_at))} ago
        </Typography>
      </CardContent>
      <CardActions>
        <Button href={makeLink(device)} size="small">
          Abrir
        </Button>
      </CardActions>
    </Card>
  );
}

// export default function DeviceCard({ device }) {
//   return (
//     <Box mb={2}>
//       <Button variant="contained" key={device.serial} href={makeLink(device)}>
//         {device.serial}
//       </Button>
//     </Box>
//   );
// }
